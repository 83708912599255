import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Icon,
  Label,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import type { ChangeEvent, FormEvent } from 'react';
import type { MarblesDepartment, GetRulesByDepartmentIdQueryVariables } from 'types/__generated__';

import { MutationStatus, UserAction } from 'types/__generated__';
import { Select } from 'components/Select/Select';
import { useCreateTopicSet } from 'hooks/useCreateTopicSet';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetDepartments } from 'hooks/useGetDepartments';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { COURSE_REGISTRATION, TOPIC_SET } from 'routes';

const DEPARTMENT_SELECT = 'department-select';
const DEPARTMENT_ERROR = 'department-error';
const NAME_INPUT = 'name-input';
const NAME_ERROR = 'name-error';
const TITLE_NL_INPUT = 'title-nl-input';
const TITLE_NL_ERROR = 'title-nl-error';
const TITLE_EN_INPUT = 'title-en-input';
const TITLE_EN_ERROR = 'title-en-error';

type Props = Omit<GetRulesByDepartmentIdQueryVariables, 'includeParentDepartments'>;

export function NewTopicSet({ departmentId, academicYear }: Props) {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'new-topic-set' });
  const currentLanguage = useCurrentLanguage();
  const departments = useGetDepartments();
  const { open, close, isOpen } = useOverlayTriggerState({});
  const [error, setError] = useState<string>();
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [departmentError, setDepartmentError] = useState<string>();
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>();
  const [titleNL, setTitleNL] = useState<string>('');
  const [titleNLError, setTitleNLError] = useState<string>();
  const [titleEN, setTitleEN] = useState<string>('');
  const [titleENError, setTitleENError] = useState<string>();

  const [mutate, mutation] = useCreateTopicSet(departmentId);
  const readableMutationStatus = useGetReadableMutationStatus();
  const navigate = useNavigate();

  function resetAndClose() {
    setError(undefined);
    setSelectedDepartment('');
    setDepartmentError(undefined);
    setName('');
    setNameError(undefined);
    setTitleNL('');
    setTitleNLError(undefined);
    setTitleEN('');
    setTitleENError(undefined);
    close();
  }

  function onSelectChance(event: ChangeEvent<HTMLSelectElement>) {
    setDepartmentError(undefined);
    setSelectedDepartment(event.target.value);
  }

  function onNameInputChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
    setNameError(undefined);
  }

  function onTitleNLInputChange(event: ChangeEvent<HTMLInputElement>) {
    setTitleNL(event.target.value);
    setTitleNLError(undefined);
  }

  function onTitleENInputChange(event: ChangeEvent<HTMLInputElement>) {
    setTitleEN(event.target.value);
    setTitleENError(undefined);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (
      !selectedDepartment ||
      selectedDepartment.trim() === '' ||
      !name ||
      name.trim() === '' ||
      !titleNL ||
      titleNL.trim() === '' ||
      !titleEN ||
      titleEN.trim() === ''
    ) {
      if (!selectedDepartment || selectedDepartment.trim() === '') {
        setDepartmentError(t(`modal.${DEPARTMENT_ERROR}`));
      }

      if (!name || name.trim() === '') {
        setNameError(t('modal.admin-name-error'));
      }

      if (!titleNL || titleNL.trim() === '') {
        setTitleNLError(t('modal.topicset-nl-error'));
      }

      if (!titleEN || titleEN.trim() === '') {
        setTitleENError(t('modal.topicset-en-error'));
      }

      return;
    }

    mutate({
      variables: {
        academicYear,
        departmentId: selectedDepartment,
        name: name,
        titleNL,
        titleEN,
      },
      onCompleted(data) {
        const { mutationStatus } = data.createTopicSet;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();

          if (data.createTopicSet.topicSet) {
            navigate(
              generatePath(`${COURSE_REGISTRATION}/${TOPIC_SET}`, {
                topicSetId: data.createTopicSet.topicSet.id,
              })
            );
          }

          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  if (
    !departments ||
    !departments.some((department) => department.allowedUserActions.includes(UserAction.EditTopicSets))
  ) {
    return null;
  }

  function departmentsParentIdFilter(departments: Array<MarblesDepartment>, parentIdentifier: string | null) {
    return departments.filter(({ parentId }) => parentId === parentIdentifier);
  }

  const [topLevelId] = departmentsParentIdFilter(departments, null).map(({ id }) => id);

  const orgLevel2departmentIds = departmentsParentIdFilter(departments, topLevelId).map(({ id }) => id);

  const orgLevel3departments = departments.filter(
    ({ parentId }) => parentId && orgLevel2departmentIds.includes(parentId)
  );

  const orgLevel3SelectableDepartments = orgLevel2departmentIds.includes(departmentId)
    ? departmentsParentIdFilter(orgLevel3departments, departmentId)
    : orgLevel3departments.filter(({ id }) => id === departmentId);

  const departmentsSelectOptions = orgLevel3SelectableDepartments
    .filter(({ allowedUserActions }) => allowedUserActions.includes(UserAction.EditTopicSets))
    .map(({ code, id, name }) => ({
      code,
      value: id,
      name,
    }));

  return (
    <>
      <Button variant="primary" onClick={open}>
        <Icon name="Plus" size={20} />
        {t('trigger')}
      </Button>
      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        isDismissable={!mutation.loading}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={false}>
              {t('modal.buttons.add-and-edit')}
            </Button>
            <Button variant="secondary" onClick={resetAndClose} disabled={false}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        onSubmit={onSubmit}
        wide
      >
        <FormField outerSpace>
          <Label htmlFor={DEPARTMENT_SELECT}>{t('modal.department')}</Label>
          <Select
            id={DEPARTMENT_SELECT}
            value={selectedDepartment}
            onChange={onSelectChance}
            aria-describedby={departmentError ? DEPARTMENT_ERROR : undefined}
          >
            <Select.Option />
            {[...departmentsSelectOptions].map((department) => (
              <Select.Option key={department.code} value={department.value}>
                {department.name[currentLanguage]}
              </Select.Option>
            ))}
          </Select>
          {departmentError && <FeedbackBox id={DEPARTMENT_ERROR} level="error" feedback={departmentError} />}
        </FormField>

        <FormField outerSpace>
          <Label htmlFor={NAME_INPUT}>{t('modal.admin-name-label')}</Label>
          <InputField
            id={NAME_INPUT}
            value={name || ''}
            onChange={onNameInputChange}
            autoComplete="off"
            aria-describedby={nameError ? NAME_ERROR : undefined}
          />
          {nameError && <FeedbackBox level="error" feedback={nameError} />}
        </FormField>
        <FormField outerSpace>
          <Label htmlFor={TITLE_NL_INPUT}>{t('modal.topicset-nl-label')}</Label>
          <InputField
            id={TITLE_NL_INPUT}
            value={titleNL || ''}
            onChange={onTitleNLInputChange}
            autoComplete="off"
            aria-describedby={titleNLError ? TITLE_NL_ERROR : undefined}
          />
          {titleNLError && <FeedbackBox level="error" feedback={titleNLError} />}
        </FormField>
        <FormField outerSpace>
          <Label htmlFor={TITLE_EN_INPUT}>{t('modal.topicset-en-label')}</Label>
          <InputField
            id={TITLE_EN_INPUT}
            value={titleEN || ''}
            onChange={onTitleENInputChange}
            autoComplete="off"
            aria-describedby={titleENError ? TITLE_EN_ERROR : undefined}
          />
          {titleENError && <FeedbackBox level="error" feedback={titleENError} />}
        </FormField>
        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
