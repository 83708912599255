import { useLazyQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Icon,
  Label,
  Spinner,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  CopyRequirementMutationInput,
  GetDepartmentsByRequirementIdQuery,
  GetDepartmentsByRequirementIdQueryVariables,
  GetRequirementsByDepartmentIdQuery,
} from 'types/__generated__';

import { GET_DEPARTMENTS_BY_REQUIREMENT_ID } from 'graphql/queries/getDepartmentsByRequirementId';
import { MutationStatus } from 'types/__generated__';
import { Select } from 'components/Select/Select';
import { useCopyRequirement } from 'hooks/useCopyRequirement';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { COURSE_REGISTRATION, ENTRY_REQUIREMENT } from 'routes';

interface Props {
  academicYear: number;
  isDisabled?: boolean;
  origin: GetRequirementsByDepartmentIdQuery['requirements'][number];
}

const COPY_REQUIREMENT_NAME_ID = 'copy_requirement_name_id';
const COPY_REQUIREMENT_NAME_ERROR_ID = 'copy_requirement_name_error_id';

const COPY_REQUIREMENT_DEPARTMENT_ID = 'copy_requirement_department_id';
const COPY_REQUIREMENT_DEPARTMENT_ERROR_ID = 'copy_requirement_department_error_id';

export function CopyEntryRequirement({ isDisabled, origin, academicYear }: Props) {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'copy-entry-requirement' });
  const currentLanguage = useCurrentLanguage();
  const { open, close, isOpen } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [nameFieldError, setNameFieldError] = useState('');
  const [departmentId, setDepartmentId] = useState<CopyRequirementMutationInput['departmentId']>();
  const [departmentIdError, setDepartmentIdError] = useState('');
  const [childDepartmentIds, setChildDepartmentIds] = useState(['']);
  const [mutationError, setMutationError] = useState('');

  const [query, lazyQuery] = useLazyQuery<
    GetDepartmentsByRequirementIdQuery,
    GetDepartmentsByRequirementIdQueryVariables
  >(GET_DEPARTMENTS_BY_REQUIREMENT_ID);
  const [mutate, mutation] = useCopyRequirement(academicYear, childDepartmentIds);

  useEffect(() => {
    if (!origin || !isOpen) return;

    async function fetchDepartments() {
      try {
        await query({ variables: { requirementId: origin.id } });
      } catch (exception) {
        const error = exception as DOMException;

        if (error.name !== 'AbortError') {
          throw error;
        }
      }
    }

    fetchDepartments();
  }, [origin, query, isOpen]);

  const childDepartmentIdsOfParentId = (
    parentId: string,
    departmentsObject: GetDepartmentsByRequirementIdQuery['requirementCopyDepartments']
  ) => {
    const departmentsArray = Object.values(departmentsObject);
    const childDepartments = departmentsArray.filter((obj) => obj.parentId === parentId);
    return childDepartments.map((childDepartment) => childDepartment.id);
  };

  useEffect(() => {
    if (lazyQuery.data && departmentId) {
      setChildDepartmentIds(childDepartmentIdsOfParentId(departmentId, lazyQuery.data.requirementCopyDepartments));
    }
  }, [lazyQuery.data, departmentId]);

  function onOpen() {
    open();
    setName(`${t('modal.copy')}: ${origin.name}`);
  }

  function resetAndClose() {
    setNameFieldError('');
    setDepartmentIdError('');
    setMutationError('');
    setName('');

    close();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setName(value);
    setNameFieldError('');
  }

  function onSelect(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setDepartmentId(value);
    setDepartmentIdError('');
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!name.trim() || !departmentId) {
      if (!name.trim()) {
        setNameFieldError(t('modal.no-name'));
      }

      if (!departmentId) {
        setDepartmentIdError(t('modal.no-department'));
      }

      return;
    }

    mutate({
      variables: { requirementId: origin.id, departmentId, name },
      onCompleted(data) {
        const { mutationStatus } = data.copyRequirement;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();

          if (data.copyRequirement.requirement) {
            navigate(
              generatePath(`${COURSE_REGISTRATION}/${ENTRY_REQUIREMENT}`, {
                id: data.copyRequirement.requirement.id,
              })
            );
          }

          return;
        }

        setMutationError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setMutationError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <Button variant="secondary" onClick={onOpen} disabled={isDisabled}>
        <Icon name="DocumentDuplicate" size={20} />
        {t('trigger')}
      </Button>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" disabled={mutation.loading || lazyQuery.loading} type="submit">
              {t('modal.buttons.confirm')}
            </Button>
            <Button onClick={resetAndClose} variant="secondary">
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>{t('modal.description', { name: origin.name })}</p>

        <FormField outerSpace>
          <Label htmlFor={COPY_REQUIREMENT_NAME_ID}>{t('modal.name-label')}</Label>
          <InputField
            aria-labelledby={nameFieldError ? COPY_REQUIREMENT_NAME_ERROR_ID : ''}
            id={COPY_REQUIREMENT_NAME_ID}
            onChange={onChange}
            value={name}
          />

          {nameFieldError && (
            <FeedbackBox id={COPY_REQUIREMENT_NAME_ERROR_ID} level="error" feedback={nameFieldError} />
          )}
        </FormField>

        <FormField outerSpace>
          <Label htmlFor={COPY_REQUIREMENT_DEPARTMENT_ID}>{t('modal.department-label')}</Label>
          {lazyQuery.loading && <Spinner ariaValueText={t('modal.loading')} />}
          <Select
            id={COPY_REQUIREMENT_DEPARTMENT_ID}
            value={departmentId}
            onChange={onSelect}
            disabled={lazyQuery.loading}
          >
            <Select.Option value=""></Select.Option>
            {lazyQuery.data?.requirementCopyDepartments.map((department) => (
              <Select.Option key={department.id} value={department.id}>
                {department.name[currentLanguage]}
              </Select.Option>
            ))}
          </Select>

          {departmentIdError && (
            <FeedbackBox id={COPY_REQUIREMENT_DEPARTMENT_ERROR_ID} level="error" feedback={departmentIdError} />
          )}
        </FormField>

        {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
      </ModalDialog>
    </>
  );
}
