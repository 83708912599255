import { useLazyQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Label, InputField, Spinner, FormField, EmptyStateDataDisplay } from '@uva-glass/component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useSearchParams } from 'react-router';

import type { ChangeEvent, FormEvent } from 'react';

import { UploadCreditLimitExceptionsModal } from './UploadCreditLimitExceptionsModal/UploadCreditLimitExceptionsModal';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { ProgrammeList } from 'App/shared/ProgrammeList/ProgrammeList';
import { FormWrapper } from 'components/FormWrapper/FormWrapper';
import { UserAction, type FindStudentsQuery, type FindStudentsQueryVariables } from 'types/__generated__';
import { Page } from 'components/Page/Page';
import { FIND_STUDENTS } from 'graphql/queries/findStudents';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { STUDENTS } from 'routes';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

const QUERY_ID = 'query';
const MIN_QUERY_LENGTH = 4;

type RowData = FindStudentsQuery['students']['searchResults'][number];

const columnHelper = createColumnHelper<RowData>();

const initialState = {
  sorting: [{ id: 'surname', desc: false }],
};

export function Students() {
  const { t } = useTranslation('student', { keyPrefix: 'students' });
  const [lazyQuery, query] = useLazyQuery<FindStudentsQuery, FindStudentsQueryVariables>(FIND_STUDENTS, {
    fetchPolicy: 'no-cache',
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('q') ?? '');
  const userCanUploadCreditLimitExceptions = useAuthorisation(UserAction.UploadCreditLimitExceptions);

  const columns = [
    columnHelper.accessor('studentNumber', {
      header: t('results.student-number'),
      cell: ({ row, cell }) => (
        <Link to={generatePath(`${STUDENTS}/:studentId`, { studentId: row.original.id })} state={{ searchQuery }}>
          {cell.getValue()}
        </Link>
      ),
    }),

    columnHelper.accessor('surname', {
      header: t('results.surname'),
    }),

    columnHelper.accessor('givenName', {
      header: t('results.given-name'),
    }),

    columnHelper.accessor('enrolments', {
      header: t('results.programme'),
      cell: ({ cell }) => <ProgrammeList asInlineList includeSubplans includeDates enrolments={cell.getValue()} />,
      filterFn: (row, _, filterValue: string) =>
        row.original.enrolments.some(
          ({ plan }) => plan.description.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0
        ),
    }),
  ];

  useEffect(() => {
    const query = searchParams.get('q');

    if (query) {
      lazyQuery({ variables: { query } });
    }
  }, [lazyQuery, searchParams]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const query = searchQuery.trim();

    setSearchParams(`q=${query}`, { replace: true });
  };

  /** @todo FormWrapper has its own margin but should be laid out using a parent component */
  return (
    <Page title={t('title')}>
      <FormWrapper onSubmit={handleSubmit}>
        <FormField>
          <Label htmlFor={QUERY_ID}>{t('label')}</Label>
          <InputField
            id={QUERY_ID}
            value={searchQuery}
            onChange={handleChange}
            autoComplete="off"
            disabled={query.loading}
          />
        </FormField>
        <Button
          variant="primary"
          type="submit"
          disabled={query.loading || searchQuery.trim().length < MIN_QUERY_LENGTH}
        >
          {t('search')}
        </Button>
        {userCanUploadCreditLimitExceptions && <UploadCreditLimitExceptionsModal />}
      </FormWrapper>

      {query.called &&
        (query.loading ? (
          <Spinner ariaValueText={t('searching')} />
        ) : query.data?.students.searchResults.length ? (
          <DataTable
            data={query.data.students.searchResults}
            columns={columns}
            initialState={initialState}
            tableId={TABLE_STORAGE_KEYS.STUDENTS}
          />
        ) : (
          <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
        ))}
    </Page>
  );
}
