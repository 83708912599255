import { gql } from '@apollo/client';

import { REQUIREMENTS_FRAGMENT } from 'graphql/fragments/requirementsFragment';
import { STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/studentWithEnrolmentsByAcademicYearFragment';

export const REGISTER_STUDENT_FOR_COURSE = gql`
  mutation registerStudentForCourse(
    $studentId: String!
    $courseOfferingId: String!
    $studentGroupId: String!
    $academicYear: Int
  ) {
    registerStudentForCourse(
      input: {
        studentId: $studentId
        courseOfferingId: $courseOfferingId
        studentGroupId: $studentGroupId
        skipRequirements: true
      }
    ) {
      mutationStatus
      coursePursuitId
      courseOfferingId
      student {
        ...studentWithEnrolmentsByAcademicYear
      }
      studentGroup {
        id
        code
        name
      }
      registrationDate
      reason
      requirementsCheck {
        ...requirements
      }
    }
  }
  ${REQUIREMENTS_FRAGMENT}
  ${STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT}
`;
