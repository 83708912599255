import { Navigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { ExportAllGroups } from './DepartmentCourseOfferings/ExportAllGroups';
import { ExportAllRegistrations } from './DepartmentCourseOfferings/ExportAllRegistrations';
import { ExportCoursesAndRequirements } from './DepartmentCourseOfferings/ExportCoursesAndRequirements';
import { ExportPendingRegistrations } from './DepartmentCourseOfferings/ExportPendingRegistrations';
import { ExportRegisteredAndPendingRegistrations } from './DepartmentCourseOfferings/ExportRegisteredAndPendingRegistrations';
import { ExportRegisteredRegistrations } from './DepartmentCourseOfferings/ExportRegisteredRegistrations';
import { ExportRejectedRegistrations } from './DepartmentCourseOfferings/ExportRejectedRegistrations';
import { UploadMultiCourseDeregistrationsModal } from './DepartmentCourseOfferings/UploadMultiCourseDeregistrationsModal/UploadMultiCourseDeregistrationsModal';
import { UploadMultiCourseRegistrationsModal } from './DepartmentCourseOfferings/UploadMultiCourseRegistrationsModal/UploadMultiCourseRegistrationsModal';

import { ActionList } from 'App/shared/ActionList/ActionList';
import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { UNAUTHORISED } from 'routes';
import { UserAction } from 'types/__generated__';
import { RequireAuthorisation, useAuthorisation } from 'providers/AuthorisationProvider';
import { Page } from 'components/Page/Page';
import { DepartmentCourseOfferings } from 'App/CourseOfferings/DepartmentCourseOfferings/DepartmentCourseOfferings';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { useGetDepartmentDetails } from 'hooks/useGetDepartmentDetails';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';

export function CourseOfferings() {
  const { t } = useTranslation('course-offerings', { keyPrefix: 'course-offerings' });
  const currentLanguage = useCurrentLanguage();
  const { academicYear } = useParams();
  const selectedAcademicYear = useGetAcademicYearDetails(Number(academicYear));
  const selectedDepartment = useGetDepartmentDetails();
  const userCanEditRegistrations = useAuthorisation(UserAction.EditRegistrations);

  return (
    <Page
      title={t('title')}
      subTitle={<PageMultiSubTitle values={[selectedAcademicYear.name, selectedDepartment.name[currentLanguage]]} />}
      notification={<ArchivedYearNotification academicYear={selectedAcademicYear} />}
      actions={
        <>
          {userCanEditRegistrations && !selectedAcademicYear.isArchived && (
            <ActionList label={t('import-action-list.label')} prefixIcon="ArrowDownOnSquare">
              <UploadMultiCourseRegistrationsModal academicYear={selectedAcademicYear.id} />
              <UploadMultiCourseDeregistrationsModal academicYear={selectedAcademicYear.id} />
            </ActionList>
          )}
          <ActionList label={t('export-action-list.label')} prefixIcon="ArrowUpOnSquare">
            <ExportAllRegistrations departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
            <ExportAllGroups departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
            <ExportPendingRegistrations departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
            <ExportRegisteredRegistrations
              departmentId={selectedDepartment.id}
              academicYear={selectedAcademicYear.id}
            />
            <ExportRegisteredAndPendingRegistrations
              departmentId={selectedDepartment.id}
              academicYear={selectedAcademicYear.id}
            />
            <ExportRejectedRegistrations departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
            <ExportCoursesAndRequirements departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
          </ActionList>
        </>
      }
    >
      <RequireAuthorisation
        authorisation={UserAction.View}
        departmentId={selectedDepartment.id}
        fallback={<Navigate to={UNAUTHORISED} />}
      >
        <DepartmentCourseOfferings departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
      </RequireAuthorisation>
    </Page>
  );
}
