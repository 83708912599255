import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { Card, Icon, LinkButton, Spinner, Tooltip } from '@uva-glass/component-library';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import type {
  GetCourseOfferingRequirementCapacityQuery,
  GetCourseOfferingRequirementCapacityQueryVariables,
} from 'types/__generated__';

import styles from './CourseOfferingEntryRequirements.module.css';
import { EntryRequirementHeader } from './EntryRequirementHeader';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { RulesInRequirement } from 'App/shared/RulesInRequirement/RulesInRequirement';
import { CardCollection } from 'components/CardCollection/CardCollection';
import { GET_COURSE_OFFERING_REQUIREMENT_CAPACITY } from 'graphql/queries/getCourseOfferingRequirementCapacity';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { COURSE_REGISTRATION, RULE } from 'routes';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type RowData = GetCourseOfferingRequirementCapacityQuery['courseOfferingRequirementCapacity'][number];

const columnHelper = createColumnHelper<RowData>();
const initialState = {
  sorting: [{ id: 'allocationRound', desc: false }],
};

export function CourseOfferingEntryRequirements() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-entry-requirements' });
  const currentLanguage = useCurrentLanguage();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { academicYear, department, requirementNodeId } = marblesCourseOffering;

  const query = useQuery<GetCourseOfferingRequirementCapacityQuery, GetCourseOfferingRequirementCapacityQueryVariables>(
    GET_COURSE_OFFERING_REQUIREMENT_CAPACITY,
    {
      variables: { courseOfferingId: marblesCourseOffering.id },
      fetchPolicy: 'cache-and-network',
    }
  );

  const columns = [
    columnHelper.accessor('allocationRound', {
      header: t('headers.allocation-round'),
      meta: { tableCellProps: { isNumeric: true } },
      enableSorting: false,
    }),

    columnHelper.accessor(({ description }) => description?.[currentLanguage], {
      id: 'description',
      header: t('headers.description'),
      cell: ({ cell, row }) => {
        const cellValue = cell.getValue();
        const isRemainingOrRest = row.original.ruleId === '';

        return !isRemainingOrRest ? (
          <LinkButton
            variant="blank"
            to={generatePath(`${COURSE_REGISTRATION}/${RULE}`, {
              ruleId: row.original.ruleId ?? '',
            })}
          >
            {cellValue}
          </LinkButton>
        ) : (
          cellValue
        );
      },
      enableSorting: false,
    }),

    columnHelper.accessor('pending', {
      header: t('headers.pending'),
      meta: { tableCellProps: { isNumeric: true } },
      enableSorting: false,
    }),

    columnHelper.accessor('registered', {
      header: t('headers.registered'),
      meta: { tableCellProps: { isNumeric: true } },
      enableSorting: false,
    }),

    columnHelper.accessor('priority', {
      header: t('headers.priority'),
      meta: { tableCellProps: { isNumeric: true } },
      enableSorting: false,
    }),

    columnHelper.accessor('priorityLimit', {
      header: t('headers.priority-limit'),
      meta: { tableCellProps: { isNumeric: true } },
      enableSorting: false,
    }),

    columnHelper.accessor(
      ({ includeInRetry, priorityLimit }) =>
        priorityLimit ? (includeInRetry ? t('values.yes') : t('values.no')) : null,
      {
        id: 'includeInRetry',
        header: () => (
          <Tooltip
            element={
              <div className={styles['include-in-retry']}>
                <span>{t('headers.include-in-retry')}</span>
                <Icon name="InformationCircle" size={20} />
              </div>
            }
            id="credits-tooltip"
            text={t('tooltips.include-in-retry')}
            size="large"
          />
        ),
        enableSorting: false,
      }
    ),
  ];

  if (query.loading) return <Spinner ariaValueText={t('loading')} />;
  if (!query.data || query.error) return <>error...</>;

  return (
    <CardCollection>
      <Card
        header={
          <EntryRequirementHeader
            academicYear={academicYear}
            department={department}
            requirementId={requirementNodeId || ''}
          />
        }
      ></Card>
      {requirementNodeId && (
        <>
          <Card>
            <DataTable
              columns={columns}
              data={query.data.courseOfferingRequirementCapacity}
              initialState={initialState}
              enableColumnFilters={false}
              tableId={TABLE_STORAGE_KEYS.ENTRY_REQUIREMENT}
            />
          </Card>
          <Card>
            <RulesInRequirement requirementId={requirementNodeId} />
          </Card>
        </>
      )}
    </CardCollection>
  );
}
