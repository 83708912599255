import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { IconButton, Icon } from '@uva-glass/component-library';

import styles from './SigninOrSignout.module.css';

import { useAuth } from 'providers/AuthProvider';
import { Username } from 'App/shared/Username/Username';

export const SigninOrSignout = () => {
  const { t } = useTranslation('common', { keyPrefix: 'signin-or-signout' });
  const user = useAuth();
  const navigate = useNavigate();

  const onClickSignin = () => {
    navigate('/login');
  };

  const onClickSignout = () => {
    navigate('/logout');
  };

  if (user === null)
    return (
      <IconButton onClick={onClickSignin} aria-label={t('signin')} noBorder>
        <Icon name="ArrowLeftEndOnRectangle" size={20} />
      </IconButton>
    );

  return (
    <div className={styles['signout']}>
      <Username />
      <IconButton onClick={onClickSignout} aria-label={t('signout')} noBorder>
        <Icon name="ArrowLeftStartOnRectangle" size={20} />
      </IconButton>
    </div>
  );
};
