import { SelectListbox } from '@uva-glass/component-library';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import type { SelectListboxProps } from '@uva-glass/component-library';
import type { MarblesDepartment } from 'types/__generated__';

import styles from './AcademicYearAndDepartmentSelector.module.css';

import { DepartmentOption } from 'components/AcademicYearAndDepartmentSelector/components/DepartmentOption/DepartmentOption';
import { usePersistCourseOfferingsFilter } from 'components/AcademicYearAndDepartmentSelector/hooks/usePersistCourseOfferingsFilter';
import { courseOfferingsFilterVar } from 'graphql/courseOfferingsFilterVar';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useFilters } from 'providers/FiltersProvider';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';
import { remove } from 'util/localStorage';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

export type DepartmentList = {
  value: string;
  label: string;
  selectedLabel?: string;
  type?: string;
  disabled?: boolean;
};

export interface AcademicYearAndDepartmentSelectorProps {
  showDepartmentFilter: boolean;
}

const createDepartmentTree = (flatArray: MarblesDepartment[], currentLanguage: 'NL' | 'EN'): DepartmentList[] => {
  return flatArray
    .filter((department) => department.code !== 'UvA')
    .sort(alphabeticalSortBy('name', currentLanguage))
    .reduce((departments: DepartmentList[], department) => {
      if (department.parentId === 'oh:department:UvA') {
        const childDepartments: DepartmentList[] = flatArray.reduce(
          (childDepartments: DepartmentList[], currentDepartment) => {
            if (currentDepartment.parentId === department.id && currentDepartment.allowedUserActions.length !== 0) {
              childDepartments.push({
                value: currentDepartment.id,
                label: `${currentDepartment.name[currentLanguage]} (${currentDepartment.code})`,
                selectedLabel: currentDepartment.code,
                type: 'level3',
                disabled: currentDepartment.allowedUserActions.length === 0,
              });
            }
            return childDepartments;
          },
          []
        );
        if (childDepartments.length !== 0) {
          childDepartments[childDepartments.length - 1].type = 'level3--last';
          departments.push(
            {
              value: department.id,
              label: `${department.name[currentLanguage]} (${department.code})`,
              selectedLabel: department.code,
              type: department.allowedUserActions.length !== 0 ? 'level2' : 'level2--disabled',
              disabled: department.allowedUserActions.length === 0,
            },
            ...childDepartments
          );
        }
      }
      return departments;
    }, []);
};

export const AcademicYearAndDepartmentSelector = ({ showDepartmentFilter }: AcademicYearAndDepartmentSelectorProps) => {
  const currentLanguage = useCurrentLanguage();
  const navigate = useNavigate();
  const previousFilterValue = courseOfferingsFilterVar();
  const { persistDepartmentId, persistAcademicYear } = usePersistCourseOfferingsFilter();
  const { departments, academicYears, courseOfferingsFilter } = useFilters();
  const { departmentId, academicYear } = courseOfferingsFilter;

  const onDepartmentIdChangeHandler = ({ value }: SelectListboxProps['options'][number]) => {
    if ((value as string) === previousFilterValue.departmentId) return;
    // reset all table filters
    Object.keys(TABLE_STORAGE_KEYS).forEach((key) => {
      remove(key);
    });

    persistDepartmentId({ departmentId: value as string });
    const newPathname = location.pathname.replace(/oh:department:\w+/, value as string);
    navigate(newPathname);
  };

  const onAcademicYearChangeHandler = ({ value }: SelectListboxProps['options'][number]) => {
    if (!value || (value as number) === previousFilterValue.academicYear) return;
    persistAcademicYear({ academicYear: value as number });
    const newPathname = location.pathname.replace(/\d{4}/, value as string);
    navigate(newPathname);
  };

  const departmentTree = useMemo(() => {
    if (departments) {
      return createDepartmentTree(departments, currentLanguage);
    }
    return [];
  }, [departments, currentLanguage]);

  const academicYearOptions = useMemo(() => {
    if (academicYears) {
      return academicYears.map(({ id, name }) => ({ value: id, label: name }));
    }
    return [];
  }, [academicYears]);

  return (
    <div className={styles['academic-year-and-department-selector-container']}>
      <div className={styles['academic-year-and-department-selector__year-selector']}>
        {academicYears.length && (
          <SelectListbox
            options={academicYearOptions}
            defaultValue={academicYear}
            variant={showDepartmentFilter ? 'pillLeft' : 'pill'}
            size="small"
            buttonLabelBold
            onChange={onAcademicYearChangeHandler}
          >
            {academicYearOptions.map(({ value, label }, index) => (
              <SelectListbox.SelectOption option={{ value, label }} index={index} key={`${value}`}>
                {label}
              </SelectListbox.SelectOption>
            ))}
          </SelectListbox>
        )}
      </div>
      {showDepartmentFilter && (
        <div className={styles['academic-year-and-department-selector__department-selector']}>
          {departmentTree.length && (
            <SelectListbox
              options={departmentTree}
              defaultValue={departmentId}
              maxOptionHeight="25rem"
              buttonLabelProp="selectedLabel"
              buttonLabelBold
              optionPositionRight
              variant="pillRight"
              size="small"
              onChange={onDepartmentIdChangeHandler}
            >
              {departmentTree.map(({ value, label, selectedLabel, type, disabled }, index) => (
                <SelectListbox.SelectOption
                  option={{ value, label, selectedLabel, disabled }}
                  index={index}
                  key={`${value}`}
                >
                  <DepartmentOption label={label} type={type} />
                </SelectListbox.SelectOption>
              ))}
            </SelectListbox>
          )}
        </div>
      )}
    </div>
  );
};
