import { Link } from 'react-router';
import { Icon } from '@uva-glass/component-library';

import type { PropsWithChildren, ReactNode } from 'react';
import type { To } from 'react-router';

import styles from './Page.module.css';

import { Header } from 'App/shared/Header/Header';
import { Helmet } from 'App/shared/Helmet/Helmet';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

interface Props {
  /** Page H1 text content */
  title?: string | ReactNode;
  defaultDocumentTitle?: string;
  /** \<title\> tag text content */
  documentTitle?: string | string[];
  header?: ReactNode;
  titleTemplate?: string;
  notification?: ReactNode;
  actions?: ReactNode;
  note?: string | number;
  backlink?: { to: To; text: string };
  subTitle?: ReactNode;
}

const defaultTitleTemplate = 'UvA - GLASS: %s';

export const getTitle = (documentTitle: string | string[], template = defaultTitleTemplate) => {
  if (Array.isArray(documentTitle)) {
    return documentTitle.reduce((acc, val) => acc.replace(/%s/, val), template);
  }

  return template.replace(/%s/g, documentTitle);
};

export function Page(props: PropsWithChildren<Props>) {
  const {
    actions,
    backlink,
    children,
    defaultDocumentTitle = '',
    documentTitle,
    header,
    note,
    notification,
    subTitle = null,
    title,
    titleTemplate = defaultTitleTemplate,
  } = props;
  const currentLanguage = useCurrentLanguage();

  return (
    <div className={styles['page']}>
      <Helmet>
        <html lang={currentLanguage.toLocaleLowerCase()} />
        <title>{getTitle(documentTitle || defaultDocumentTitle, titleTemplate)}</title>
      </Helmet>

      {header || <Header />}

      {notification}

      <main className={styles['page__content']}>
        {title && (
          <header className={styles['page__header']}>
            {backlink && (
              <div className={styles['page__breadcrumbs']}>
                <Icon name="ArrowSmallLeft" size={16} />
                <Link to={backlink.to} className={styles['page__breadcrumbs-item']}>
                  {backlink.text}
                </Link>
              </div>
            )}

            <div className={styles['page__title-container']}>
              <div>
                <h1 className={styles.page__title}>{title}</h1>
                {subTitle && <span className={styles['page__sub-title']}>{subTitle}</span>}
              </div>
              {actions && <div className={styles['page__actions']}>{actions}</div>}
            </div>
            {note && <div className={styles['page__note']}>{note}</div>}
          </header>
        )}
        {children}
      </main>
    </div>
  );
}
