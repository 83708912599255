import { gql } from '@apollo/client';

import { REQUIREMENTS_FRAGMENT } from 'graphql/fragments/requirementsFragment';
import { STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/studentWithEnrolmentsByAcademicYearFragment';

export const CREATE_PENDING_COURSE_PURSUIT = gql`
  mutation createPendingCoursePursuit(
    $courseOfferingId: String!
    $studentNumber: String!
    $topicId: String
    $academicYear: Int
  ) {
    administrativelySelectCourseForRegistration(
      input: {
        courseOfferingId: $courseOfferingId
        studentNumber: $studentNumber
        topicId: $topicId
        skipRequirements: true
      }
    ) {
      mutationStatus
      courseOfferingId
      coursePursuitId
      topicId
      student {
        ...studentWithEnrolmentsByAcademicYear
      }
      clusterPreference {
        index
        pursuitCount
        registrationLimit
      }
      requirementsCheck {
        ...requirements
      }
    }
  }
  ${REQUIREMENTS_FRAGMENT}
  ${STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT}
`;
