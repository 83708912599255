import { Outlet } from 'react-router';

import type { UserAction } from 'types/__generated__';

import { AuthorisationProvider, RequireAuthorisation } from 'providers/AuthorisationProvider';
import { FiltersProvider } from 'providers/FiltersProvider';
import { RequireAuth } from 'routes/RequireAuth';
import { Unauthorized } from 'App/Unauthorized/Unauthorized';

interface Props {
  userAction: UserAction;
}

export const ProtectedRoute = ({ userAction }: Props) => (
  <RequireAuth>
    <AuthorisationProvider>
      <RequireAuthorisation authorisation={userAction} fallback={<Unauthorized />}>
        <FiltersProvider>
          <Outlet />
        </FiltersProvider>
      </RequireAuthorisation>
    </AuthorisationProvider>
  </RequireAuth>
);
