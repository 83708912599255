import { useLazyQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Label,
  FieldHint,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  Fieldset,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  CreateRuleRequiredStudentSetMutationVariables,
  GetStudentSetByCodeQuery,
  GetStudentSetByCodeQueryVariables,
  StudentSetFragment,
} from 'types/__generated__';

import { useCreateRuleRequiredStudentSet } from './hooks/useCreateRuleRequiredStudentSet';
import styles from './StudentSet.module.css';

import { GET_STUDENT_SET_BY_CODE } from 'graphql/queries/getStudentSetByCode';
import { MutationStatus } from 'types/__generated__';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { COURSE_REGISTRATION, RULE } from 'routes';

type Props = Pick<CreateRuleRequiredStudentSetMutationVariables, 'academicYear' | 'departmentId'>;

const ID_DESCRIPTION_ERROR = 'ID_DESCRIPTION_ERROR';
const ID_FORM_ERROR = 'ID_FORM_ERROR';
const ID_INPUT_NAME = 'ID_INPUT_NAME';
const ID_INPUT_STUDENT_SET = 'ID_INPUT_STUDENT_SET';
const ID_STUDENT_SET_ERROR = 'ID_STUDENT_SET_ERROR';

export function StudentSet({ academicYear, departmentId }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'new-student-set-rule' });
  const currentLanguage = useCurrentLanguage();
  const { open, close, isOpen } = useOverlayTriggerState({});

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string>();
  const [studentSet, setStudentSet] = useState<StudentSetFragment | null>();
  const [studentSetId, setStudentSetId] = useState('');
  const [studentSetIdError, setStudentSetIdError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();

  const [query, lazyQuery] = useLazyQuery<GetStudentSetByCodeQuery, GetStudentSetByCodeQueryVariables>(
    GET_STUDENT_SET_BY_CODE
  );
  const [mutate, mutation] = useCreateRuleRequiredStudentSet();
  const readableMutationStatus = useGetReadableMutationStatus();
  const navigate = useNavigate();

  function resetAndClose() {
    setName('');
    setNameError(undefined);
    setStudentSet(null);
    setStudentSetId('');
    setStudentSetIdError(undefined);
    setSubmitError(undefined);
    close();
  }

  function onCancel() {
    resetAndClose();
  }

  function onChangeName(event: ChangeEvent<HTMLInputElement>) {
    setNameError(undefined);
    setName(event.target.value);
  }

  function onChangeStudentSetId(event: ChangeEvent<HTMLInputElement>) {
    const { validity, value } = event.target;
    const code = value.trim().toUpperCase();

    setSubmitError(undefined);
    setStudentSetIdError(undefined);
    setStudentSetId(code);

    if (!validity.valid) {
      setStudentSet(null);

      return;
    }

    query({
      variables: { code },
      onCompleted(data) {
        setStudentSet(data.studentSet);

        if (!data.studentSet) {
          setStudentSetIdError(t('invalid-student-set-id'));
        }
      },
      onError() {
        setStudentSetIdError(readableMutationStatus('unknown'));
      },
    });
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSubmitError(undefined);

    if (!name || name.trim() === '' || !studentSet) {
      if (!name || name.trim() === '') {
        setNameError(t('fill-in-a-type', { type: t('modal.name-label').toLowerCase() }));
      }

      if (!studentSet) {
        setStudentSetIdError(t('fill-in-a-type', { type: t('modal.student-set-label').toLowerCase() }));
      }

      return;
    }

    mutate({
      variables: {
        academicYear,
        departmentId,
        name,
        studentSetId: studentSet.id,
      },
      onCompleted(data) {
        if (data.createRuleRequiredStudentSet.mutationStatus === MutationStatus.Success) {
          if (data.createRuleRequiredStudentSet.rule) {
            navigate(
              generatePath(`${COURSE_REGISTRATION}/${RULE}`, {
                ruleId: data.createRuleRequiredStudentSet.rule.id,
              })
            );
          }

          return;
        }

        setStudentSetIdError(readableMutationStatus(data.createRuleRequiredStudentSet.mutationStatus));
      },
      onError() {
        setSubmitError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <button onClick={open}>{t('trigger')}</button>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        isDismissable={!mutation.loading && !lazyQuery.loading}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.add-and-edit')}
            </Button>
            <Button variant="secondary" onClick={onCancel} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        noValidate
        onSubmit={onSubmit}
        wide
      >
        <Fieldset legend="">
          <FormField>
            <Label htmlFor={ID_INPUT_NAME}>{t('modal.name-label')}</Label>
            <InputField
              aria-describedby={nameError ? ID_DESCRIPTION_ERROR : undefined}
              id={ID_INPUT_NAME}
              onChange={onChangeName}
              value={name}
            />
            {nameError && <FeedbackBox id={ID_DESCRIPTION_ERROR} feedback={nameError} level="error" />}
          </FormField>

          <FormField>
            <Label id={`${ID_INPUT_STUDENT_SET}-label`} htmlFor={ID_INPUT_STUDENT_SET}>
              {t('modal.student-set-label')}
            </Label>

            <span id={`${ID_INPUT_STUDENT_SET}-sublabel`} className={styles['student-set__sublabel']}>
              {t('modal.student-must-be-registered')}
            </span>

            <InputField
              aria-describedby={studentSetIdError ? ID_STUDENT_SET_ERROR : undefined}
              aria-labelledby={`${ID_INPUT_STUDENT_SET}-sublabel`}
              disabled={mutation.loading}
              id={ID_INPUT_STUDENT_SET}
              maxLength={10}
              onChange={onChangeStudentSetId}
              pattern="\w{10}"
              placeholder={t('modal.student-set-code')}
              value={studentSetId}
            />
            <FieldHint
              value={studentSet ? `[${studentSet.title[currentLanguage]}]` : undefined}
              loading={lazyQuery.loading}
              spinnerAriaValueText={t('modal.loading-student-set')}
            />

            {studentSetIdError && <FeedbackBox id={ID_STUDENT_SET_ERROR} feedback={studentSetIdError} level="error" />}
          </FormField>
        </Fieldset>

        {submitError && <FeedbackBox id={ID_FORM_ERROR} feedback={submitError} level="error" />}
      </ModalDialog>
    </>
  );
}
