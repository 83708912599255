import { useQuery } from '@apollo/client';
import { FeedbackBox, Periods, Spinner } from '@uva-glass/component-library';
import { Trans, useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router';

import type {
  GetConstrainedChoiceClusterByIdQuery,
  GetConstrainedChoiceClusterByIdQueryVariables,
} from 'types/__generated__';

import styles from './ConstrainedChoiceDrawer.module.css';

import { Tabs } from 'components/Tabs/Tabs';
import { GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID } from 'graphql/queries/getConstrainedChoiceClusterById';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { COURSE_OFFERING, COURSE_REGISTRATION } from 'routes';

interface Props {
  constrainedChoiceClusterId: string;
}

export function ConstrainedChoiceDrawer({ constrainedChoiceClusterId }: Props) {
  const { t } = useTranslation('constrained-choices', { keyPrefix: 'constrained-choice-drawer' });
  const currentLanguage = useCurrentLanguage();

  const query = useQuery<GetConstrainedChoiceClusterByIdQuery, GetConstrainedChoiceClusterByIdQueryVariables>(
    GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID,
    {
      variables: { constrainedChoiceClusterId },
    }
  );

  if (query.loading) {
    return <Spinner ariaValueText={t('loading')} />;
  }

  if (query.error || !query.data?.constrainedChoiceCluster) {
    return <FeedbackBox feedback={t('error')} level="error" />;
  }

  const { constrainedChoiceCluster } = query.data;
  const { offerings, registrationLimit } = constrainedChoiceCluster;

  const tabItems = [
    {
      id: 'course-offerings',
      label: t('linked-to-courses', { count: offerings.length }),
      disabled: !offerings.length,
      content: (
        <ul className={styles['constrained-choice-drawer__courses']}>
          {[...offerings]
            .sort((offering1, offering2) =>
              (offering1.info?.title[currentLanguage] ?? '').localeCompare(offering2.info?.title[currentLanguage] ?? '')
            )
            .map((courseOffering) => (
              <li key={courseOffering.id} className={styles['constrained-choice-drawer__courses-item']}>
                <div className={styles['constrained-choice-drawer__courses-item-details']}>
                  <Link
                    to={generatePath(`${COURSE_REGISTRATION}/${COURSE_OFFERING}`, {
                      courseOfferingId: courseOffering.id,
                    })}
                  >
                    {courseOffering.info?.title[currentLanguage]}
                  </Link>
                  <span className={styles['constrained-choice-drawer__courses-item-ec']}>
                    {courseOffering.info?.ec} EC
                  </span>
                </div>
                <div className={styles['constrained-choice-drawer__courses-item-details']}>
                  <span>{courseOffering.info?.catalogNumber}</span>
                  <span>
                    <Periods
                      periods={courseOffering.periods}
                      tooltipTranslations={{
                        periods: t('periods.periods'),
                        period: t('periods.period'),
                        and: t('periods.and'),
                      }}
                    />
                  </span>
                </div>
              </li>
            ))}
        </ul>
      ),
    },
  ];

  return (
    <div className={styles['constrained-choice-drawer__content']}>
      <div className={styles['constrained-choice-drawer__constrained-choice-registration-limit']}>
        <Trans
          t={t}
          i18nKey="notification-registration-limit"
          values={{ limit: registrationLimit }}
          components={{ strong: <strong /> }}
        />{' '}
      </div>

      <Tabs items={tabItems} />
    </div>
  );
}
