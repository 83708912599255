import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router';
import { EmptyStateDataDisplay } from '@uva-glass/component-library';

import type { HeaderContext } from '@tanstack/react-table';
import type { ReactNode } from 'react';
import type {
  GetStudentPursuitAuditByStudentIdQuery,
  GetStudentPursuitAuditByStudentIdQueryVariables,
} from 'types/__generated__';

import { COURSE_OFFERING, COURSE_REGISTRATION } from 'routes';
import { CoursePursuitAction } from 'types/__generated__';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { GET_STUDENT_PURSUIT_AUDIT_BY_STUDENT_ID } from 'graphql/queries/getStudentPursuitAuditByStudentId';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { TableFilterSelect } from 'App/shared/TableFilterSelect/TableFilterSelect';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useFormattedDate } from 'hooks/useFormattedDate';

type RowData = GetStudentPursuitAuditByStudentIdQuery['studentPursuitAudit'][number];
type Props = GetStudentPursuitAuditByStudentIdQueryVariables;

const columnHelper = createColumnHelper<RowData>();

const initialState = {
  sorting: [{ id: 'when', desc: true }],
};

export function StudentHistoryLog({ studentId }: Props) {
  const { t } = useTranslation('student', { keyPrefix: 'student-history-log' });
  const currentLanguage = useCurrentLanguage();
  const formattedDate = useFormattedDate();
  const query = useQuery<GetStudentPursuitAuditByStudentIdQuery, GetStudentPursuitAuditByStudentIdQueryVariables>(
    GET_STUDENT_PURSUIT_AUDIT_BY_STUDENT_ID,
    { variables: { studentId } }
  );

  const getActionTranslation = useCallback(
    (
      key: CoursePursuitAction,
      variables: Record<'old' | 'new' | 'course' | 'deregistrationReason', string | undefined> &
        Record<'courseOfferingId', string | null | undefined>,
      children?: ReactNode
    ) => (
      <Trans
        t={t}
        i18nKey={`action.${key}`}
        values={variables}
        components={{
          courseLink:
            variables.courseOfferingId && variables.course !== t('values.deleted-course') ? (
              <Link
                to={generatePath(`${COURSE_REGISTRATION}/${COURSE_OFFERING}`, {
                  courseOfferingId: variables.courseOfferingId,
                })}
              />
            ) : (
              <>course</>
            ),
        }}
      >
        {children}
      </Trans>
    ),
    [t]
  );

  const getDeletedValueText = (coursePursuitAction: CoursePursuitAction) =>
    coursePursuitAction === CoursePursuitAction.UpdatedTopic ? t('values.deleted-topic') : t('values.deleted-group');

  const columns = [
    columnHelper.accessor('timeStamp', {
      id: 'when',
      header: t('headers.when'),
      cell: ({ cell }) => <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />,
      sortingFn: 'datetime',
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.timeStamp, { dateStyle: 'medium', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),

    columnHelper.accessor('username', {
      id: 'who',
      header: t('headers.who'),
      meta: {
        Filter: ({ table, column }: HeaderContext<RowData, unknown>) => {
          const options = Array.from(
            new Set(table.getPreFilteredRowModel().flatRows.map((row) => row.getValue<string>(column.id)))
          ).map((value) => ({ value, content: value }));

          return <TableFilterSelect column={column} label={t('filter-labels.who')} options={options} />;
        },
      },
    }),

    columnHelper.accessor(({ academicYear }) => academicYear?.name, {
      id: 'academicYear',
      header: t('headers.academic-year'),
      cell: ({ cell }) => cell.getValue() || '-',
      meta: {
        Filter: ({ table, column }: HeaderContext<RowData, unknown>) => {
          const options = Array.from(
            new Set(table.getPreFilteredRowModel().flatRows.map((row) => row.getValue<string>(column.id)))
          )
            .filter(Boolean)
            .map((value) => ({ value, content: value }));

          return <TableFilterSelect column={column} label={t('filter-labels.academic-year')} options={options} />;
        },
      },
    }),

    columnHelper.accessor(({ registrationRound }) => registrationRound?.description[currentLanguage], {
      id: 'registrationRound',
      header: t('headers.registration-round'),
      cell: ({ cell }) => cell.getValue() || '-',
      meta: {
        Filter: ({ table, column }: HeaderContext<RowData, unknown>) => {
          const options = Array.from(
            new Set(table.getPreFilteredRowModel().flatRows.map((row) => row.getValue<string>(column.id)))
          )
            .filter(Boolean)
            .map((value) => ({ value, content: value }));

          return <TableFilterSelect column={column} label={t('filter-labels.registration-round')} options={options} />;
        },
      },
    }),

    columnHelper.accessor('catalogNumber', {
      header: t('headers.catalog-number'),
    }),

    columnHelper.accessor('action', {
      header: t('headers.action'),
      cell: ({ cell, row }) => {
        const { action } = row.original;
        const oldValue = row.original.old ? row.original.old[currentLanguage] : getDeletedValueText(action);
        const newValue = row.original.new ? row.original.new[currentLanguage] : getDeletedValueText(action);
        const course = row.original.course ? row.original.course[currentLanguage] : t('values.deleted-course');
        const { courseOfferingId, deregistrationReason } = row.original;
        const reasonTranslation = deregistrationReason
          ? t(`reason.${deregistrationReason}`)
          : t('reason.no-reason-given');

        return getActionTranslation(cell.getValue(), {
          old: oldValue,
          new: newValue,
          course,
          courseOfferingId,
          deregistrationReason: reasonTranslation,
        });
      },
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];

  if (query.loading) return null;
  if (query.error || !query.data?.studentPursuitAudit) return <>error</>;

  const { studentPursuitAudit } = query.data;

  return studentPursuitAudit.length ? (
    <DataTable
      columns={columns}
      data={studentPursuitAudit}
      initialState={initialState}
      tableId={TABLE_STORAGE_KEYS.STUDENT_HISTORY_LOG}
    />
  ) : (
    <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
  );
}
