import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, EmptyStateDataDisplay, Icon, LinkButton } from '@uva-glass/component-library';

import type {
  GetCourseOfferingsQueryVariables,
  GetRulesByDepartmentIdQuery,
  GetRulesByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { CopyRule } from './CopyRule';
import { RemoveRule } from './RemoveRule';

import { UserAction } from 'types/__generated__';
import { COURSE_REGISTRATION, RULE } from 'routes';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';
import { RuleDrawer } from 'App/shared/RuleDrawer/RuleDrawer';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';

type RowData = GetRulesByDepartmentIdQuery['rules'][number];

const columnHelper = createColumnHelper<RowData>();

export function RequirementRulesTable({ departmentId, academicYear }: GetCourseOfferingsQueryVariables) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'requirement-rules-table' });
  const currentLanguage = useCurrentLanguage();
  const academicYearDetails = useGetAcademicYearDetails(academicYear);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [currentRule, setCurrentRule] = useState<RowData | null>(null);

  const userCanEdit = currentRule?.department?.allowedUserActions.includes(UserAction.EditRequirements);
  const query = useQuery<GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables>(
    GET_RULES_BY_DEPARTMENT_ID,
    { variables: { departmentId, academicYear } }
  );
  const rules = useMemo(() => query.data?.rules || [], [query.data?.rules]);
  const initialState = { sorting: [{ id: 'name', desc: false }] };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('table.headers.name'),
        cell: ({ cell, row }) => (
          <Button
            variant="blank"
            onClick={() => {
              setDrawerIsOpen(true);
              setCurrentRule(row.original);
            }}
          >
            {cell.getValue()}
          </Button>
        ),
        sortingFn: 'text',
      }),

      columnHelper.accessor(({ requirements }) => requirements.length, {
        id: 'requirementCount',
        header: t('table.headers.requirement-count'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor(({ department }) => department.name[currentLanguage], {
        id: 'department',
        header: t('table.headers.department'),
      }),

      columnHelper.display({
        id: 'actions',
        cell: ({ row }) =>
          row.original.department.allowedUserActions.includes(UserAction.EditRequirements) &&
          !academicYearDetails.isArchived ? (
            <RemoveRule rule={row.original} isDisabled={row.getValue<number>('requirementCount') > 0} />
          ) : null,
        meta: { tableCellProps: { hasButton: true } },
      }),
    ],
    [currentLanguage, t, academicYearDetails]
  );

  if (query.loading) return null;
  if (query.error || !rules) return <>error</>;

  return (
    <>
      {drawerIsOpen && currentRule && (
        <Drawer
          isOpen={drawerIsOpen}
          onClose={() => {
            setDrawerIsOpen(false);
            setCurrentRule(null);
          }}
          title={currentRule?.name}
          buttons={
            <>
              {userCanEdit && academicYear && !academicYearDetails.isArchived && (
                <CopyRule origin={currentRule} academicYear={academicYear} />
              )}
              <LinkButton
                variant="secondary"
                to={generatePath(`${COURSE_REGISTRATION}/${RULE}`, {
                  ruleId: currentRule.id,
                })}
              >
                {userCanEdit && !academicYearDetails.isArchived ? (
                  <>
                    <Icon name="PencilSquare" size={20} />
                    {t('drawer.edit')}
                  </>
                ) : (
                  <>
                    <Icon name="DocumentText" size={20} />
                    {t('drawer.view')}
                  </>
                )}
              </LinkButton>
            </>
          }
        >
          <RuleDrawer ruleId={currentRule.id} />
        </Drawer>
      )}
      {rules.length ? (
        <DataTable
          data={rules}
          columns={columns}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.RULES_TABLE}
        />
      ) : (
        <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
      )}
    </>
  );
}
