import { useLazyQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Icon,
  Label,
  Spinner,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import type { ChangeEvent, FormEvent } from 'react';
import type { CopyRuleMutationInput, GetDepartmentsQuery, GetRulesByDepartmentIdQuery } from 'types/__generated__';

import { useCopyRule } from './hooks/useCopyRule';

import { GET_DEPARTMENTS } from 'graphql/queries/getDepartments';
import { Select } from 'components/Select/Select';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { COURSE_REGISTRATION, RULE } from 'routes';
import { MutationStatus, UserAction } from 'types/__generated__';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';

interface Props {
  academicYear: number;
  isDisabled?: boolean;
  origin: GetRulesByDepartmentIdQuery['rules'][number];
}

const COPY_RULE_NAME_ID = 'copy_rule_name_id';
const COPY_RULE_NAME_ERROR_ID = 'copy_rule_name_error_id';

const COPY_RULE_DEPARTMENT_ID = 'copy_rule_department_id';
const COPY_RULE_DEPARTMENT_ERROR_ID = 'copy_rule_department_error_id';

export function CopyRule({ isDisabled, origin, academicYear }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'copy-rule' });
  const currentLanguage = useCurrentLanguage();
  const { open, close, isOpen } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const navigate = useNavigate();

  const [name, setName] = useState<string>('');
  const [nameFieldError, setNameFieldError] = useState('');
  const [departmentId, setDepartmentId] = useState<CopyRuleMutationInput['departmentId']>();
  const [departmentIdError, setDepartmentIdError] = useState('');
  const [childDepartmentIds, setChildDepartmentIds] = useState(['']);
  const [mutationError, setMutationError] = useState('');

  const [query, lazyQuery] = useLazyQuery<GetDepartmentsQuery>(GET_DEPARTMENTS);
  const [mutate, mutation] = useCopyRule(academicYear, childDepartmentIds);

  useEffect(() => {
    if (!origin || !isOpen) return;

    async function fetchDepartments() {
      try {
        await query();
      } catch (exception) {
        const error = exception as DOMException;

        if (error.name !== 'AbortError') {
          throw error;
        }
      }
    }

    fetchDepartments();
  }, [origin, query, isOpen]);

  const childDepartmentIdsOfParentId = (
    parentId: string,
    departmentsObject: GetDepartmentsQuery['marblesDepartments']
  ) => {
    const departmentsArray = Object.values(departmentsObject);
    const childDepartments = departmentsArray.filter((obj) => obj.parentId === parentId);
    return childDepartments.map((childDepartment) => childDepartment.id);
  };

  useEffect(() => {
    if (lazyQuery.data && departmentId) {
      setChildDepartmentIds(childDepartmentIdsOfParentId(departmentId, lazyQuery.data.marblesDepartments));
    }
  }, [lazyQuery.data, departmentId]);

  function onOpen() {
    open();
    setName(`${t('modal.copy')}: ${origin.name}`);
  }

  function resetAndClose() {
    setNameFieldError('');
    setDepartmentIdError('');
    setMutationError('');
    setName('');
    setDepartmentId('');

    close();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setName(value);
    setNameFieldError('');
  }

  function onSelect(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setDepartmentId(value);
    setDepartmentIdError('');
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!name.trim() || !departmentId) {
      if (!name.trim()) {
        setNameFieldError(t('modal.no-name'));
      }

      if (!departmentId) {
        setDepartmentIdError(t('modal.no-department'));
      }

      return;
    }

    mutate({
      variables: { ruleId: origin.id, departmentId, name },
      onCompleted(data) {
        const { mutationStatus } = data.copyRule;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();

          if (data.copyRule.rule) {
            navigate(
              generatePath(`${COURSE_REGISTRATION}/${RULE}`, {
                ruleId: data.copyRule.rule.id,
              })
            );
          }

          return;
        }

        setMutationError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setMutationError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <Button variant="secondary" onClick={onOpen} disabled={isDisabled}>
        <Icon name="DocumentDuplicate" size={20} />
        {t('trigger')}
      </Button>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" disabled={mutation.loading || lazyQuery.loading} type="submit">
              {t('modal.buttons.confirm')}
            </Button>
            <Button onClick={resetAndClose} variant="secondary">
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>{t('modal.description', { name: origin.name })}</p>

        <FormField outerSpace>
          <Label htmlFor={COPY_RULE_NAME_ID}>{t('modal.name-label')}</Label>
          <InputField
            aria-labelledby={nameFieldError ? COPY_RULE_NAME_ERROR_ID : ''}
            id={COPY_RULE_NAME_ID}
            onChange={onChange}
            value={name}
          />

          {nameFieldError && <FeedbackBox id={COPY_RULE_NAME_ERROR_ID} level="error" feedback={nameFieldError} />}
        </FormField>

        <FormField outerSpace>
          <Label htmlFor={COPY_RULE_DEPARTMENT_ID}>{t('modal.department-label')}</Label>
          {lazyQuery.loading && <Spinner ariaValueText={t('modal.loading')} />}
          <Select id={COPY_RULE_DEPARTMENT_ID} value={departmentId} onChange={onSelect} disabled={lazyQuery.loading}>
            <Select.Option value=""></Select.Option>
            {lazyQuery.data &&
              lazyQuery.data.marblesDepartments
                .filter((department) => department.allowedUserActions.includes(UserAction.EditRequirements))
                .sort(alphabeticalSortBy('name', currentLanguage))
                .map((department) => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.name[currentLanguage]}
                  </Select.Option>
                ))}
          </Select>

          {departmentIdError && (
            <FeedbackBox id={COPY_RULE_DEPARTMENT_ERROR_ID} level="error" feedback={departmentIdError} />
          )}
        </FormField>

        {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
      </ModalDialog>
    </>
  );
}
