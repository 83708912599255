import { clsx } from 'clsx';
import { NavLink } from 'react-router';

import type { To } from 'react-router';

import styles from './TabBar.module.css';

type TabBarItem = { to: To; text: string; when?: boolean };

interface Props {
  tabs: Array<TabBarItem>;
  rest?: Array<TabBarItem>;
}

export const TabBar = ({ tabs, rest }: Props) => (
  <nav className={styles['tab-bar']}>
    {tabs
      .filter(({ when = true }) => when)
      .map(({ to, text }) => (
        <NavLink
          key={text}
          to={to}
          replace
          className={({ isActive }) =>
            clsx(styles['tab-bar__item'], { [styles['tab-bar__item--is-active']]: isActive })
          }
          end
        >
          {text}
        </NavLink>
      ))}
    {rest && (
      <>
        <hr className={styles['tab-bar__divider']} />
        {rest
          .filter(({ when = true }) => when)
          .map(({ to, text }) => (
            <NavLink
              key={text}
              to={to}
              replace
              className={({ isActive }) =>
                clsx(styles['tab-bar__item'], { [styles['tab-bar__item--is-active']]: isActive })
              }
              end
            >
              {text}
            </NavLink>
          ))}
      </>
    )}
  </nav>
);
