import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, Link } from 'react-router';
import { EmptyStateDataDisplay, Spinner } from '@uva-glass/component-library';

import type { HeaderContext } from '@tanstack/react-table';
import type { GetUsersQuery } from 'types/__generated__';

import { AUTHORISATIONS } from 'routes';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { GET_USERS } from 'graphql/queries/getUsers';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { TableFilterSelect } from 'App/shared/TableFilterSelect/TableFilterSelect';
import { useGetReadableUserStatus } from 'hooks/useGetReadableUserStatus';

type RowData = GetUsersQuery['marblesUsers'][number];
type roleAssignments = GetUsersQuery['marblesUsers'][number]['roleAssignments'];
const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'username', desc: false }] };

const formatDepartmentCodes = (row: roleAssignments) =>
  row
    .map(({ department }) => department.code)
    .reduce((uniqueCodes: string[], code) => {
      if (!uniqueCodes.includes(code)) {
        uniqueCodes.push(code);
      }
      return uniqueCodes;
    }, [])
    .sort()
    .join(', ');

export function AuthorisationTable() {
  const { t } = useTranslation('authorisations', { keyPrefix: 'authorisations-page' });
  const readableUserStatus = useGetReadableUserStatus();

  const getUsersQuery = useQuery<GetUsersQuery>(GET_USERS, { fetchPolicy: 'cache-and-network' });

  const columns = useMemo(
    () => [
      columnHelper.accessor(({ username }) => username, {
        id: 'username',
        header: t('table.headers.username'),
        cell: (cell) => (
          <Link to={generatePath(`${AUTHORISATIONS}/:username`, { username: cell.getValue() })}>{cell.getValue()}</Link>
        ),
        sortingFn: 'text',
      }),

      columnHelper.accessor(
        (user) => (user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.username),
        {
          id: 'name',
          header: t('table.headers.name'),
          sortingFn: 'text',
        }
      ),

      columnHelper.accessor('roleAssignments', {
        id: 'department',
        header: t('table.headers.department'),
        cell: ({ cell }) => formatDepartmentCodes(cell.getValue()),
        filterFn: (row, _, filterValue: string) =>
          row.original.roleAssignments
            .map((roleAssignment) => roleAssignment.department.code.toLocaleLowerCase())
            .some((departmentCode) => departmentCode.includes(filterValue.toLocaleLowerCase())),
        sortingFn: (rowA, rowB) => {
          const formattedRowA = formatDepartmentCodes(rowA.original.roleAssignments);
          const formattedRowB = formatDepartmentCodes(rowB.original.roleAssignments);
          return formattedRowA > formattedRowB ? 1 : formattedRowA < formattedRowB ? -1 : 0;
        },
      }),

      columnHelper.accessor('status', {
        id: 'status',
        header: t('table.headers.status'),
        cell: ({ cell }) => readableUserStatus(cell.getValue()),
        meta: {
          Filter: ({ table, column }: HeaderContext<RowData, unknown>) => {
            const options: Array<{ value: string; content: string }> = Array.from(
              new Set(table.getPreFilteredRowModel().flatRows.map((row) => row.getValue<string>(column.id)))
            ).map((value) => ({ value, content: readableUserStatus(value) }));

            return <TableFilterSelect column={column} label={t('table.headers.status')} options={options} />;
          },
        },
        sortingFn: 'text',
      }),
    ],
    [t, readableUserStatus]
  );

  if (getUsersQuery.loading) return <Spinner ariaValueText={''} />;

  if (!getUsersQuery.data?.marblesUsers) return null;

  const { marblesUsers } = getUsersQuery.data;

  return (
    <>
      {marblesUsers.length ? (
        <DataTable
          columns={columns}
          data={marblesUsers}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.USERS_TABLE}
        />
      ) : (
        <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
      )}
    </>
  );
}
