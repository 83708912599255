import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router';

import type { GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables } from 'types/__generated__';

import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';

export function useGetCourseOffering() {
  const { courseOfferingId } = useParams<keyof GetCourseOfferingByIdQueryVariables>();
  const client = useApolloClient();

  if (!courseOfferingId) throw new Error('No course offering id found.');

  const cached = client.readQuery<GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables>({
    query: GET_COURSE_OFFERING_BY_ID,
    variables: { courseOfferingId },
  });

  if (!cached?.marblesCourseOffering) {
    throw new Error(
      'The useGetCourseOffering hook can only be used in a child of a component that performed the GET_COURSE_OFFERING_BY_ID query'
    );
  }

  const { marblesCourseOffering, courseOfferingGroupSwaps } = cached;

  return { marblesCourseOffering, courseOfferingGroupSwaps };
}
