import { StrictMode, Suspense } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router';
import { useErrorBoundary } from 'use-error-boundary';
import { Spinner } from '@uva-glass/component-library';

import { CourseOfferingEntryRequirements } from './CourseOffering/CourseOfferingEntryRequirements/CourseOfferingEntryRequirements';
import { CourseOfferingGroupSwap } from './CourseOffering/CourseOfferingGroupSwap/CourseOfferingGroupSwap';
import { CourseOfferingGroups } from './CourseOffering/CourseOfferingGroups/CourseOfferingGroups';
import { CourseOfferingPendingStudents } from './CourseOffering/CourseOfferingPendingStudents/CourseOfferingPendingStudents';
import { CourseOfferingRegisteredStudents } from './CourseOffering/CourseOfferingRegisteredStudents/CourseOfferingRegisteredStudents';
import { CourseOfferingRejections } from './CourseOffering/CourseOfferingRejections/CourseOfferingRejections';
import { CourseOfferingSettings } from './CourseOffering/CourseOfferingSettings/CourseOfferingSettings';
import { CourseOfferingTimetable } from './CourseOffering/CourseOfferingTimetable/CourseOfferingTimetable';
import { CourseOfferingTopics } from './CourseOffering/CourseOfferingTopics/CourseOfferingTopics';

import { Authorisations } from 'App/Authorisations/Authorisations';
import { UserAuthorisation } from 'App/Authorisations/UserAuthorisation/UserAuthorisation';
import { Callback } from 'App/Callback/Callback';
import { ConstrainedChoice } from 'App/ConstrainedChoices/ConstrainedChoice/ConstrainedChoice';
import { ConstrainedChoices } from 'App/ConstrainedChoices/ConstrainedChoices';
import { CourseOffering } from 'App/CourseOffering/CourseOffering';
import { CourseOfferings } from 'App/CourseOfferings/CourseOfferings';
import { EntryRequirement } from 'App/EntryRequirements/EntryRequirement/EntryRequirement';
import { EntryRequirements } from 'App/EntryRequirements/EntryRequirements';
import { ErrorPage } from 'App/ErrorPage/ErrorPage';
import { GroupSwap } from 'App/GroupSwap/GroupSwap';
import { Login } from 'App/Login/Login';
import { Logout } from 'App/Logout/Logout';
import { RegistrationRounds } from 'App/RegistrationRounds/RegistrationRounds';
import { RequirementRules } from 'App/RequirementRules/RequirementRules';
import { Rule } from 'App/RequirementRules/Rule/Rule';
import { Student } from 'App/Student/Student';
import { Students } from 'App/Students/Students';
import { TopicSet } from 'App/TopicSets/TopicSet/TopicSet';
import { TopicSets } from 'App/TopicSets/TopicSets';
import { Unauthorized } from 'App/Unauthorized/Unauthorized';
import { UserBlocked } from 'App/UserBlocked/UserBlocked';
import { AppStatus } from 'App/shared/AppStatus/AppStatus';
import { ProtectedRoute } from 'App/shared/ProtectedRoute/ProtectedRoute';
import { Providers } from 'providers';
import { AcademicYearAndDepartmentRouteProvider } from 'providers/AcademicYearAndDepartmentRouteProvider';
import * as routes from 'routes';
import { UserAction } from 'types/__generated__';

export function App() {
  const { ErrorBoundary, didCatch } = useErrorBoundary({
    onDidCatch(_exception) {
      // log exceptions in Application Insights
    },
  });

  return (
    <StrictMode>
      <Suspense fallback={<Spinner ariaValueText={''} />}>
        <Providers>
          <BrowserRouter>
            {didCatch ? (
              <ErrorPage />
            ) : (
              <ErrorBoundary>
                <AppStatus>
                  <Routes>
                    <Route
                      path={routes.APP_ROOT}
                      element={<Navigate to={`${routes.COURSE_REGISTRATION}/${routes.COURSE_OFFERINGS}`} replace />}
                    />
                    <Route path={routes.CALLBACK} element={<Callback />} />
                    <Route path={routes.LOGIN} element={<Login />} />
                    <Route path={routes.LOGOUT} element={<Logout />} />
                    <Route path={routes.UNAUTHORISED} element={<Unauthorized />} />
                    <Route path={routes.USER_BLOCKED} element={<UserBlocked />} />
                    <Route path={routes.ERROR} element={<ErrorPage />} />

                    <Route element={<ProtectedRoute userAction={UserAction.View} />}>
                      <Route
                        path={routes.COURSE_REGISTRATION}
                        element={
                          <AcademicYearAndDepartmentRouteProvider>
                            <Outlet />
                          </AcademicYearAndDepartmentRouteProvider>
                        }
                      >
                        <Route path={routes.COURSE_OFFERINGS} element={<CourseOfferings />} />
                        <Route
                          path={routes.COURSE_OFFERINGS_NO_PARAMS}
                          element={<Navigate to={routes.COURSE_OFFERINGS} replace />}
                        />

                        <Route path={routes.ENTRY_REQUIREMENTS} element={<EntryRequirements />} />
                        <Route
                          path={routes.ENTRY_REQUIREMENTS_NO_PARAMS}
                          element={<Navigate to={routes.ENTRY_REQUIREMENTS} replace />}
                        />

                        <Route path={routes.REQUIREMENT_RULES} element={<RequirementRules />} />
                        <Route
                          path={routes.REQUIREMENT_RULES_NO_PARAMS}
                          element={<Navigate to={routes.REQUIREMENT_RULES} replace />}
                        />

                        <Route path={routes.TOPIC_SETS} element={<TopicSets />} />
                        <Route
                          path={routes.TOPIC_SETS_NO_PARAMS}
                          element={<Navigate to={routes.TOPIC_SETS} replace />}
                        />

                        <Route path={routes.CONSTRAINED_CHOICES} element={<ConstrainedChoices />} />
                        <Route
                          path={routes.CONSTRAINED_CHOICES_NO_PARAMS}
                          element={<Navigate to={routes.CONSTRAINED_CHOICES} replace />}
                        />

                        <Route
                          path={routes.GROUP_SWAP}
                          element={<ProtectedRoute userAction={UserAction.ProcessGroupSwapRequests} />}
                        >
                          <Route index element={<GroupSwap />} />
                        </Route>
                        <Route
                          path={routes.GROUP_SWAP_NO_PARAMS}
                          element={<Navigate to={routes.GROUP_SWAP} replace />}
                        />

                        <Route path={routes.REGISTRATION_ROUNDS} element={<RegistrationRounds />} />
                        <Route
                          path={routes.REGISTRATION_ROUNDS_NO_PARAMS}
                          element={<Navigate to={routes.REGISTRATION_ROUNDS} replace />}
                        />
                      </Route>

                      {/* course-registration routes without AcademicYearAndDepartment-selector */}
                      <Route path={routes.COURSE_REGISTRATION} element={<Outlet />}>
                        <Route path={`${routes.COURSE_OFFERING}/*`} element={<CourseOffering />}>
                          <Route index element={<CourseOfferingGroups />} />
                          <Route path="group-swap" element={<CourseOfferingGroupSwap />} />
                          <Route path="topics" element={<CourseOfferingTopics />} />
                          <Route path="requirements" element={<CourseOfferingEntryRequirements />} />
                          <Route path="timetable" element={<CourseOfferingTimetable />} />
                          <Route path="registered" element={<CourseOfferingRegisteredStudents />} />
                          <Route path="pending" element={<CourseOfferingPendingStudents />} />
                          <Route path="rejected" element={<CourseOfferingRejections />} />
                          <Route path="settings" element={<CourseOfferingSettings />} />
                          <Route path="*" element={<Navigate to="." replace />} />
                        </Route>

                        <Route path={routes.RULE} element={<Rule />} />

                        <Route path={routes.ENTRY_REQUIREMENT} element={<EntryRequirement />} />

                        <Route path={routes.TOPIC_SET} element={<TopicSet />} />

                        <Route path={routes.CONSTRAINED_CHOICE} element={<ConstrainedChoice />} />
                      </Route>

                      <Route path={routes.STUDENTS}>
                        <Route index element={<Students />} />
                        <Route path="*" element={<Students />} />
                        <Route path=":studentId/*" element={<Student />} />
                      </Route>
                      <Route
                        path={routes.AUTHORISATIONS}
                        element={<ProtectedRoute userAction={UserAction.EditAuthorisations} />}
                      >
                        <Route index element={<Authorisations />} />
                        <Route path=":username" element={<UserAuthorisation />} />
                      </Route>
                    </Route>

                    <Route path="*" element={<Navigate to={routes.APP_ROOT} replace />} />
                  </Routes>
                </AppStatus>
              </ErrorBoundary>
            )}
          </BrowserRouter>
        </Providers>
      </Suspense>
    </StrictMode>
  );
}
