import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import {
  Button,
  ButtonGroup,
  Drawer,
  EmptyStateDataDisplay,
  Icon,
  LinkButton,
  Spinner,
} from '@uva-glass/component-library';

import type {
  GetConstrainedChoiceClustersByDepartmentIdQuery,
  GetConstrainedChoiceClustersByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { ConstrainedChoiceDrawer } from './ConstrainedChoiceDrawer/ConstrainedChoiceDrawer';
import { RemoveConstrainedChoice } from './RemoveConstrainedChoice';

import { CONSTRAINED_CHOICE, COURSE_REGISTRATION } from 'routes';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID } from 'graphql/queries/getConstrainedChoiceClustersByDepartmentId';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { UserAction } from 'types/__generated__';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';

type RowData = GetConstrainedChoiceClustersByDepartmentIdQuery['constrainedChoiceClusters'][number];

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'titleNL', desc: false }] };

export function ConstrainedChoicesTable({
  departmentId,
  academicYear,
}: GetConstrainedChoiceClustersByDepartmentIdQueryVariables) {
  const { t } = useTranslation('constrained-choices', { keyPrefix: 'constrained-choices' });
  const currentLanguage = useCurrentLanguage();
  const userCanEditConstrainedChoiceCluster = useAuthorisation(UserAction.EditConstrainedChoiceClusters, departmentId);
  const academicYearDetails = useGetAcademicYearDetails(academicYear);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [currentConstrainedChoice, setCurrentConstrainedChoice] = useState<RowData | null>(null);

  const ConstrainedChoiceClustersQuery = useQuery<
    GetConstrainedChoiceClustersByDepartmentIdQuery,
    GetConstrainedChoiceClustersByDepartmentIdQueryVariables
  >(GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID, { variables: { academicYear, departmentId } });

  const columns = useMemo(
    () => [
      columnHelper.accessor(({ title }) => title.NL, {
        id: 'titleNL',
        header: t('table.headers.name-nl'),
        cell: ({ cell }) => (
          <Button
            variant="blank"
            onClick={() => {
              setDrawerIsOpen(true);
              setCurrentConstrainedChoice(cell.row.original);
            }}
          >
            {cell.getValue()}
          </Button>
        ),
        sortingFn: 'text',
      }),

      columnHelper.accessor(({ title }) => title.EN, {
        id: 'titleEN',
        header: t('table.headers.name-en'),
        cell: ({ cell }) => (
          <Button
            variant="blank"
            onClick={() => {
              setDrawerIsOpen(true);
              setCurrentConstrainedChoice(cell.row.original);
            }}
          >
            {cell.getValue()}
          </Button>
        ),
        sortingFn: 'text',
      }),

      columnHelper.accessor(({ offerings }) => offerings.length, {
        id: 'numberOfCourses',
        header: t('table.headers.courses'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor('registrationLimit', {
        id: 'limit',
        header: t('table.headers.limit'),
        enableColumnFilter: false,
      }),

      columnHelper.display({
        id: 'actions',
        header: () => <span className="visually-hidden">{t('table.headers.actions')}</span>,
        cell: ({ row }) =>
          userCanEditConstrainedChoiceCluster &&
          !academicYearDetails.isArchived && (
            <RemoveConstrainedChoice
              constrainedChoiceCluster={row.original}
              academicYear={academicYear}
              departmentId={departmentId}
            />
          ),
        meta: { tableCellProps: { hasButton: true } },
      }),
    ],
    [t, userCanEditConstrainedChoiceCluster, academicYear, departmentId, academicYearDetails]
  );

  if (ConstrainedChoiceClustersQuery.loading) return <Spinner ariaValueText={''} />;

  if (!ConstrainedChoiceClustersQuery.data?.constrainedChoiceClusters) return null;

  const { constrainedChoiceClusters } = ConstrainedChoiceClustersQuery.data;

  return (
    <>
      {drawerIsOpen && currentConstrainedChoice && (
        <Drawer
          isOpen={drawerIsOpen}
          onClose={() => {
            setDrawerIsOpen(false);
            setCurrentConstrainedChoice(null);
          }}
          title={currentConstrainedChoice?.title[currentLanguage]}
          buttons={
            <ButtonGroup>
              <LinkButton
                variant="secondary"
                to={generatePath(`${COURSE_REGISTRATION}/${CONSTRAINED_CHOICE}`, {
                  constrainedChoiceClusterId: currentConstrainedChoice.id,
                })}
              >
                {userCanEditConstrainedChoiceCluster && !academicYearDetails.isArchived ? (
                  <>
                    <Icon name="PencilSquare" size={20} />
                    {t('drawer.buttons.edit')}
                  </>
                ) : (
                  <>
                    <Icon name="DocumentText" size={20} />
                    {t('drawer.buttons.view')}
                  </>
                )}
              </LinkButton>
            </ButtonGroup>
          }
        >
          <ConstrainedChoiceDrawer constrainedChoiceClusterId={currentConstrainedChoice.id} />
        </Drawer>
      )}

      {constrainedChoiceClusters.length ? (
        <DataTable
          columns={columns}
          data={constrainedChoiceClusters}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.CONSTRAINED_CHOICES_TABLE}
        />
      ) : (
        <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
      )}
    </>
  );
}
