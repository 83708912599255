import { useQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Icon,
  Label,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import type { ChangeEvent, FormEvent } from 'react';
import type { GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables } from 'types/__generated__';

import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';
import { MutationStatus, UserAction } from 'types/__generated__';
import { Select } from 'components/Select/Select';
import { useCreateRequirement } from 'hooks/useCreateRequirement';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { COURSE_REGISTRATION, ENTRY_REQUIREMENT } from 'routes';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';

const NAME_INPUT = 'name-nl-input';
const NAME_ERROR = 'name-nl-error';
const RULE_SELECT = 'rule-select';
const RULE_ID_ERROR = 'rule-id-error';

type Props = Omit<GetRulesByDepartmentIdQueryVariables, 'includeParentDepartments'>;

export function NewEntryRequirement({ departmentId, academicYear }: Props) {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'new-entry-requirement' });
  const { open, close, isOpen } = useOverlayTriggerState({});
  const [error, setError] = useState<string>();
  const [name, setName] = useState<string>();
  const [nameError, setNameError] = useState<string>();
  const [ruleId, setRuleId] = useState<string>();
  const [ruleIdError, setRuleIdError] = useState<string>();
  const query = useQuery<GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables>(
    GET_RULES_BY_DEPARTMENT_ID,
    { variables: { academicYear, departmentId } }
  );
  const [mutate, mutation] = useCreateRequirement();
  const readableMutationStatus = useGetReadableMutationStatus();
  const navigate = useNavigate();

  function resetAndClose() {
    setError(undefined);
    setName(undefined);
    setNameError(undefined);
    setRuleId(undefined);
    setRuleIdError(undefined);
    close();
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
    setNameError(undefined);
  }

  function onSelectChance(event: ChangeEvent<HTMLSelectElement>) {
    setRuleIdError(undefined);
    setRuleId(event.target.value);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!name || name.trim() === '' || !ruleId) {
      if (!name || name.trim() === '') {
        setNameError(t('modal.name-error'));
      }

      if (!ruleId) {
        setRuleIdError(t('modal.link-rule-error'));
      }

      return;
    }

    mutate({
      variables: { academicYear, departmentId, name, ruleId },
      onCompleted(data) {
        const { mutationStatus } = data.createRequirement;

        if (mutationStatus === MutationStatus.Success) {
          if (data.createRequirement.requirement) {
            navigate(
              generatePath(`${COURSE_REGISTRATION}/${ENTRY_REQUIREMENT}`, {
                id: data.createRequirement.requirement.id,
              })
            );
          }

          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  if (!query.data?.rules) return null;

  const { rules } = query.data;

  if (!rules.some((rule) => rule.department.allowedUserActions.includes(UserAction.EditRequirements))) return null;

  return (
    <>
      <Button variant="primary" onClick={open}>
        <Icon name="Plus" size={20} />
        {t('trigger')}
      </Button>
      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        isDismissable={!mutation.loading}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.add-and-edit')}
            </Button>
            <Button variant="secondary" onClick={resetAndClose} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        onSubmit={onSubmit}
        wide
      >
        <FormField outerSpace>
          <Label htmlFor={NAME_INPUT}>{t('modal.name-label')}</Label>
          <InputField
            id={NAME_INPUT}
            value={name || ''}
            onChange={onInputChange}
            autoComplete="off"
            aria-describedby={nameError ? NAME_ERROR : undefined}
          />
          {nameError && <FeedbackBox level="error" feedback={nameError} />}
        </FormField>
        <FormField outerSpace>
          <Label htmlFor={RULE_SELECT}>{t('modal.link-rule')}</Label>
          <Select
            id={RULE_SELECT}
            value={ruleId}
            onChange={onSelectChance}
            aria-describedby={ruleIdError ? RULE_ID_ERROR : undefined}
          >
            <Select.Option />
            {[...rules].sort(alphabeticalSortBy('name')).map((rule) => (
              <Select.Option key={rule.id} value={rule.id}>
                {rule.name} ({rule.department.code})
              </Select.Option>
            ))}
          </Select>
          {ruleIdError && <FeedbackBox id={RULE_ID_ERROR} level="error" feedback={ruleIdError} />}
        </FormField>
        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
