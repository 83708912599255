import { useEffect, useRef } from 'react';

export function useDebounce<T>(func: (...args: T[]) => unknown, secondsToWaitFor: number) {
  const timeout = useRef<ReturnType<typeof setTimeout>>(undefined);

  useEffect(
    () => () => {
      clearTimeout(timeout.current);
    },
    []
  );

  return function (...args: T[]) {
    const that = globalThis;

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      func.apply(that, args);
      clearTimeout(timeout.current);
    }, secondsToWaitFor);
  };
}
