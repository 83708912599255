import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import type { To } from 'react-router';

import { useAuth } from 'providers/AuthProvider';
import { completeLogin } from 'services/authService';

export function Callback() {
  const navigate = useNavigate();
  const user = useAuth();
  const didCallback = useRef(false);

  useEffect(() => {
    async function asyncCompleteLogin() {
      try {
        if (didCallback.current) return;

        didCallback.current = true;

        await completeLogin();
      } catch {
        navigate('/');
      }
    }

    if (user !== null) {
      const { pathname, search } = user.state || {};
      const to: To = pathname ? { pathname, search } : '/';

      navigate(to, { replace: true });
      return;
    }

    asyncCompleteLogin();
  }, [navigate, user]);

  return null;
}
