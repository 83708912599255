import { useQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Label,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  Fieldset,
  FormField,
  MultiSelect,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import type { ChangeEvent } from 'react';
import type {
  CreateRuleRequiredCareerMutationInput,
  CreateRuleRequiredCareerMutationVariables,
  GetCareersQuery,
} from 'types/__generated__';

import { useCreateRuleRequiredCareer } from './hooks/useCreateRuleRequiredCareer';

import { GET_CAREERS } from 'graphql/queries/getCareers';
import { MutationStatus } from 'types/__generated__';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { COURSE_REGISTRATION, RULE } from 'routes';

type Props = Pick<CreateRuleRequiredCareerMutationVariables, 'departmentId' | 'academicYear'>;

type CareerIds = CreateRuleRequiredCareerMutationInput['careerIds'];

const ID_INPUT_NAME = 'ID_INPUT_NAME';
const ID_ERROR_NAME = 'ID_ERROR_NAME';

export function Career({ departmentId, academicYear }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'new-career-rule' });
  const currentLanguage = useCurrentLanguage();
  const { isOpen, open, close } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const navigate = useNavigate();

  const [name, setName] = useState<string>();
  const [fieldError, setFieldError] = useState<string>();
  const [careerIds, setCareerIds] = useState<CareerIds>([]);
  const [careerIdsError, setCareerIdsError] = useState<string>();

  const query = useQuery<GetCareersQuery>(GET_CAREERS);
  const [mutate, mutation] = useCreateRuleRequiredCareer();

  function resetAndClose() {
    setName(undefined);
    setFieldError(undefined);
    setCareerIds([]);
    setCareerIdsError(undefined);
    close();
  }

  function onNameInputChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldError(undefined);
    setName(event.target.value);
  }

  function onCareerIdsInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target;

    setCareerIdsError(undefined);
    setCareerIds((prevState) => (checked ? [...prevState, value] : prevState.filter((careerId) => careerId !== value)));
  }

  function onSubmit(event: ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    const nameValue = name?.trim();

    if (!nameValue || careerIds.length === 0) {
      if (!nameValue) {
        setFieldError(t('modal.error.no-name'));
      }

      if (careerIds.length === 0) {
        setCareerIdsError(t('modal.error.no-career-ids'));
      }
      return;
    }

    mutate({
      variables: { departmentId, academicYear, name: nameValue, careerIds },
      onCompleted(data) {
        if (data.createRuleRequiredCareer.mutationStatus === MutationStatus.Success) {
          if (data.createRuleRequiredCareer.rule) {
            navigate(
              generatePath(`${COURSE_REGISTRATION}/${RULE}`, {
                ruleId: data.createRuleRequiredCareer.rule.id,
              })
            );
          }

          return;
        }

        setFieldError(readableMutationStatus(data.createRuleRequiredCareer.mutationStatus));
      },
    });
  }

  if (!query.data?.careers) return null;

  return (
    <>
      <button onClick={open}>{t('trigger')}</button>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.add-and-edit')}
            </Button>
            <Button variant="secondary" onClick={resetAndClose} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        onSubmit={onSubmit}
        wide
      >
        <FormField outerSpace>
          <Label htmlFor={ID_INPUT_NAME}>{t('modal.label-name')}</Label>
          <InputField
            id={ID_INPUT_NAME}
            value={name || ''}
            onChange={onNameInputChange}
            aria-describedby={fieldError ? ID_ERROR_NAME : undefined}
          />
          {fieldError && <FeedbackBox level="error" feedback={fieldError} id={ID_ERROR_NAME} />}
        </FormField>
        <Fieldset
          legend={t('modal.careers')}
          description={<Trans t={t} i18nKey="modal.student-must-be-registered" components={{ strong: <strong /> }} />}
        >
          <MultiSelect>
            {query.data.careers.map(({ id, code, description }) => (
              <MultiSelect.MultiSelectItem
                key={id}
                label={`${code} ${description[currentLanguage]}`}
                checked={careerIds.includes(id)}
                value={id}
                onChange={onCareerIdsInputChange}
              />
            ))}
          </MultiSelect>
          {careerIdsError && <FeedbackBox level="error" feedback={careerIdsError} />}
        </Fieldset>
      </ModalDialog>
    </>
  );
}
