import { useMutation, useQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';
import { Button, Drawer, Icon, LinkButton, Tooltip } from '@uva-glass/component-library';

import type {
  GetRequirementByIdQuery,
  GetRequirementByIdQueryVariables,
  UpdateRequirementNameMutation,
  UpdateRequirementNameMutationVariables,
  UpdateRequirementRulesMutation,
  UpdateRequirementRulesMutationVariables,
} from 'types/__generated__';

import styles from './EntryRequirement.module.css';
import { BooleanInput } from './BooleanInput';
import { NumericInput } from './NumericInput';
import { RuleInRequirementOrder } from './RuleInRequirementOrder';
import { LinkRuleInRequirement } from './LinkRuleInRequirement';
import { UnlinkRuleInRequirement } from './UnlinkRuleInRequirement';

import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { COURSE_REGISTRATION, ENTRY_REQUIREMENTS_NO_PARAMS, RULE } from 'routes';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { EditableHeading } from 'App/shared/EditableHeading/EditableHeading';
import { EntryRequirementDrawer } from 'App/EntryRequirements/EntryRequirementsTable/EntryRequirementDrawer/EntryRequirementDrawer';
import { GET_REQUIREMENT_BY_ID } from 'graphql/queries/getRequirementById';
import { Page } from 'components/Page/Page';
import { PageSection } from 'App/shared/PageSection/PageSection';
import { RowActions } from 'App/shared/DataTable/RowActions/RowActions';
import { RuleDrawer } from 'App/shared/RuleDrawer/RuleDrawer';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { Toolbar } from 'components/Toolbar/Toolbar';
import { UPDATE_REQUIREMENT_RULES } from 'graphql/mutations/updateRequirementRules';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { UserAction } from 'types/__generated__';
import { useUpdateRequirementName } from 'App/EntryRequirements/EntryRequirement/hooks/useUpdateRequirementName';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';

type RowData = NonNullable<GetRequirementByIdQuery['requirement']>['rulesInRequirement'][number];
const columnHelper = createColumnHelper<RowData>();

export function EntryRequirement() {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'entry-requirement' });
  const currentLanguage = useCurrentLanguage();
  const { id: requirementId } = useParams();

  const { open, close, isOpen } = useOverlayTriggerState({});
  const updateRequirementName = useUpdateRequirementName();

  const [requirementDrawerIsOpen, setRequirementDrawerIsOpen] = useState(false);
  const [currentRule, setCurrentRule] = useState<RowData['rule'] | null>(null);

  const updateRequirementRulesMutation = useMutation<
    UpdateRequirementRulesMutation,
    UpdateRequirementRulesMutationVariables
  >(UPDATE_REQUIREMENT_RULES);

  if (!requirementId) {
    throw new Error('No id found for entry requirement.');
  }

  const query = useQuery<GetRequirementByIdQuery, GetRequirementByIdQueryVariables>(GET_REQUIREMENT_BY_ID, {
    variables: { requirementId },
  });

  function openRuleDrawer(rule: RowData['rule']) {
    open();
    setCurrentRule(rule);
  }

  if (!query.data?.requirement) return null;

  const { requirement } = query.data;
  const { rulesInRequirement, academicYear } = requirement;

  const userCanEditEntryRequirement = requirement.department.allowedUserActions.includes(UserAction.EditRequirements);

  const columns = [
    columnHelper.accessor('allocationRound', {
      header: () => (
        <div className={styles['entry-requirement__headers-allocationRound']}>
          {t('table.headers.allocation-round')}
        </div>
      ),
      meta: { tableCellProps: { isNumeric: true } },
    }),

    columnHelper.accessor(({ rule }) => rule.name, {
      id: 'rule',
      header: t('table.headers.rule'),
      cell: ({ row, cell }) => (
        <Button
          variant="blank"
          onClick={() => {
            openRuleDrawer(row.original.rule);
          }}
        >
          {cell.getValue()}
        </Button>
      ),
    }),

    columnHelper.accessor('priority', {
      header: t('table.headers.priority'),
      cell: ({ cell, row }) =>
        userCanEditEntryRequirement && !academicYear.isArchived ? (
          <NumericInput
            cellValue={cell.getValue()}
            fieldName="priority"
            requirement={requirement}
            ruleId={row.original.rule.id}
            updateRequirementRulesMutation={updateRequirementRulesMutation}
          />
        ) : (
          cell.getValue()
        ),
      meta: { tableCellProps: { isNumeric: true } },
    }),

    columnHelper.accessor('priorityLimit', {
      header: t('table.headers.priority-limit'),
      cell: ({ cell, row }) =>
        row.getValue('priority') !== null &&
        (userCanEditEntryRequirement && !academicYear.isArchived ? (
          <NumericInput
            cellValue={cell.getValue()}
            fieldName="priorityLimit"
            requirement={requirement}
            ruleId={row.original.rule.id}
            updateRequirementRulesMutation={updateRequirementRulesMutation}
          />
        ) : (
          cell.getValue()
        )),
      meta: { tableCellProps: { isNumeric: true } },
    }),

    columnHelper.accessor('includeInRetry', {
      header: () => (
        <div className={styles['entry-requirement__headers-include-in-retry']}>
          <Tooltip
            element={
              <div className={styles['include-in-retry']}>
                <span>{t('table.headers.include-in-retry')}</span>
                <Icon name="InformationCircle" size={20} />
              </div>
            }
            id="credits-tooltip"
            text={t('table.tooltips.include-in-retry')}
            size="large"
          />
        </div>
      ),
      cell: ({ cell, row }) =>
        row.getValue('priorityLimit') !== null && (
          <BooleanInput
            cellValue={cell.getValue()}
            requirement={requirement}
            ruleId={row.original.rule.id}
            updateRequirementRulesMutation={updateRequirementRulesMutation}
            disabled={!userCanEditEntryRequirement || academicYear.isArchived}
          />
        ),
      meta: { tableCellProps: { maxWidth: 'medium' } },
    }),

    columnHelper.display({
      id: 'actions',
      header: userCanEditEntryRequirement && !academicYear.isArchived ? t('table.headers.actions') : '',
      cell: ({ row }) =>
        userCanEditEntryRequirement &&
        !academicYear.isArchived && (
          <RowActions>
            {!requirement.rulesInRequirement.length ? null : (
              <UnlinkRuleInRequirement
                requirement={requirement}
                ruleInRequirementId={row.original.id}
                updateRequirementRulesMutation={updateRequirementRulesMutation}
              />
            )}
            <RuleInRequirementOrder requirement={requirement} rowIndex={row.index} />
          </RowActions>
        ),
      meta: { tableCellProps: { hasButton: true, maxWidth: 'medium' } },
    }),
  ];

  return (
    <Page
      title={t('title', { description: requirement.name })}
      backlink={{
        to: `../${ENTRY_REQUIREMENTS_NO_PARAMS}/${requirement.academicYear.id}/${requirement.department.id}`,
        text: t('back'),
      }}
      subTitle={
        <PageMultiSubTitle values={[requirement.academicYear.name, requirement.department.name[currentLanguage]]} />
      }
      notification={<ArchivedYearNotification academicYear={requirement.academicYear} />}
    >
      <PageSection
        notification={
          <div className={styles['entry-requirement__notification']}>
            {t('used-in')}
            <Button
              variant="blank"
              onClick={() => {
                setRequirementDrawerIsOpen(true);
              }}
            >
              <Trans
                t={t}
                i18nKey="notification-used"
                values={{ courses: requirement.courseOfferings.length, topics: requirement.topics.length }}
                components={{ strong: <strong /> }}
              />
            </Button>
            <Trans
              t={t}
              i18nKey="notification-applied"
              values={{ count: requirement.courseOfferings.length + requirement.topics.length }}
              components={{ strong: <strong /> }}
            />
          </div>
        }
      >
        {userCanEditEntryRequirement && !academicYear.isArchived ? (
          <EditableHeading<UpdateRequirementNameMutation, UpdateRequirementNameMutationVariables>
            entityId={requirement.id}
            entityName={requirement.name}
            mutationName="updateRequirementName"
            mutationTuple={updateRequirementName}
            variables={{ name: requirement.name, requirementId: requirement.id }}
          />
        ) : (
          <h2 className={styles['entry-requirement__heading']}>{requirement.name}</h2>
        )}

        {requirementDrawerIsOpen && requirement && (
          <Drawer
            isOpen={requirementDrawerIsOpen}
            onClose={() => {
              setRequirementDrawerIsOpen(false);
            }}
            title={requirement.name}
          >
            <EntryRequirementDrawer requirementId={requirement.id} showRules={false} />
          </Drawer>
        )}

        {currentRule && (
          <Drawer
            isOpen={isOpen}
            onClose={() => {
              close();
              setCurrentRule(null);
            }}
            title={currentRule?.name}
            buttons={
              <LinkButton
                variant="secondary"
                to={generatePath(`${COURSE_REGISTRATION}/${RULE}`, {
                  ruleId: currentRule.id,
                })}
              >
                {userCanEditEntryRequirement && !academicYear.isArchived ? (
                  <>
                    <Icon name="PencilSquare" size={20} />
                    {t('rule-edit')}
                  </>
                ) : (
                  <>
                    <Icon name="DocumentText" size={20} />
                    {t('rule-view')}
                  </>
                )}
              </LinkButton>
            }
          >
            <RuleDrawer ruleId={currentRule.id} />
          </Drawer>
        )}

        <section>
          <header className={styles['entry-requirement__header']}>
            <h2 className={styles['entry-requirement__heading']}>{t('rules', { count: rulesInRequirement.length })}</h2>
            {userCanEditEntryRequirement && !academicYear.isArchived && (
              <Toolbar>
                <LinkRuleInRequirement requirement={requirement} />
              </Toolbar>
            )}
          </header>

          {rulesInRequirement.length ? (
            <DataTable
              columns={columns}
              data={rulesInRequirement}
              enableSorting={false}
              enableColumnFilters={false}
              tableId={TABLE_STORAGE_KEYS.ENTRY_REQUIREMENT_RULES}
            />
          ) : (
            <p>{t('rules-empty')}</p>
          )}
        </section>
      </PageSection>
    </Page>
  );
}
