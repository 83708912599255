import { useQuery } from '@apollo/client';
import { Accordion, FeedbackBox, Periods, Spinner } from '@uva-glass/component-library';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router';

import type { GetRequirementByIdQuery, GetRequirementByIdQueryVariables } from 'types/__generated__';

import styles from './EntryRequirementDrawer.module.css';

import { RuleNodes } from 'App/shared/RuleNodes/RuleNodes';
import { Tabs } from 'components/Tabs/Tabs';
import { GET_REQUIREMENT_BY_ID } from 'graphql/queries/getRequirementById';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { COURSE_OFFERING, COURSE_REGISTRATION, TOPIC_SET } from 'routes';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';

interface Props {
  requirementId: string;
  showRules?: boolean;
}

export function EntryRequirementDrawer({ requirementId, showRules = true }: Props) {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'entry-requirement-drawer' });
  const currentLanguage = useCurrentLanguage();

  const query = useQuery<GetRequirementByIdQuery, GetRequirementByIdQueryVariables>(GET_REQUIREMENT_BY_ID, {
    variables: { requirementId },
  });

  if (query.loading) {
    return <Spinner ariaValueText={t('loading')} />;
  }

  if (query.error || !query.data?.requirement) {
    return <FeedbackBox feedback={t('error')} level="error" />;
  }

  const { requirement } = query.data;
  const { rulesInRequirement, courseOfferings, topics } = requirement;

  const tabItems = [
    ...(showRules
      ? [
          {
            id: 'rules',
            label: t('linked-to-rules', { count: rulesInRequirement.length }),
            disabled: !rulesInRequirement.length,
            content: (
              <ul className={styles['entry-requirement-drawer__rules']}>
                {rulesInRequirement.map((ruleInRequirement, index) => (
                  <li className={styles['entry-requirement-drawer__rules-rule']} key={ruleInRequirement.id}>
                    <Accordion
                      title={
                        <h2 className={styles['entry-requirement-drawer__rule-title']}>
                          {ruleInRequirement.rule.name || t('no-name')}
                        </h2>
                      }
                      expanded={index === 0}
                      content={<RuleNodes rule={ruleInRequirement.rule} />}
                    />
                  </li>
                ))}
              </ul>
            ),
          },
        ]
      : []),
    {
      id: 'course-offerings',
      label: t('linked-to-courses', { count: courseOfferings.length }),
      disabled: !courseOfferings.length,
      content: (
        <ul className={styles['entry-requirement-drawer__courses']}>
          {[...courseOfferings]
            .sort((offering1, offering2) => offering1.periods[0].number - offering2.periods[0].number)
            .sort((offering1, offering2) =>
              (offering1.info?.title[currentLanguage] ?? '').localeCompare(offering2.info?.title[currentLanguage] ?? '')
            )
            .map((courseOffering) => (
              <li key={courseOffering.id} className={styles['entry-requirement-drawer__courses-item']}>
                <span>{courseOffering.info?.catalogNumber}</span>
                <Link
                  to={generatePath(`${COURSE_REGISTRATION}/${COURSE_OFFERING}`, {
                    courseOfferingId: courseOffering.id,
                  })}
                >
                  {courseOffering.info?.title[currentLanguage]}
                </Link>
                <span className={styles['entry-requirement-drawer__courses-item-periods']}>
                  <Periods
                    periods={courseOffering.periods}
                    tooltipTranslations={{
                      periods: t('periods.periods'),
                      period: t('periods.period'),
                      and: t('periods.and'),
                    }}
                  />
                </span>
              </li>
            ))}
        </ul>
      ),
    },
    {
      id: 'topics',
      label: t('linked-to-topics', { count: topics.length }),
      disabled: !topics.length,
      content: (
        <ul className={styles['entry-requirement-drawer__topics']}>
          {[...topics].sort(alphabeticalSortBy('title', currentLanguage)).map((topic) => (
            <li key={topic.id}>
              <dl>
                <dt className={styles['entry-requirement-drawer__topic-title']}>{topic.title[currentLanguage]}</dt>
                <dd>
                  {t('part-of-topic-set')}&nbsp;
                  <Link
                    to={generatePath(`${COURSE_REGISTRATION}/${TOPIC_SET}`, {
                      topicSetId: topic.topicSetId,
                    })}
                  >
                    {topic.topicSet.name}
                  </Link>
                </dd>
              </dl>
            </li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <div className={styles['entry-requirement-drawer__content']}>
      <Tabs items={tabItems} />
    </div>
  );
}
