import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Drawer, EmptyStateDataDisplay, InfoMessage, Spinner } from '@uva-glass/component-library';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router';

import type { VisibilityState } from '@tanstack/react-table';
import type {
  GetPendingPursuitsByCourseOfferingIdQuery,
  GetPendingPursuitsByCourseOfferingIdQueryVariables,
} from 'types/__generated__';

import { SetCoursePursuitTopic } from './SetCoursePursuitTopic/SetCoursePursuitTopic';

import { CreatePendingCoursePursuitModal } from 'App/CourseOffering/CreatePendingCoursePursuitModal/CreatePendingCoursePursuitModal';
import { EntryRequirementStatus } from 'App/CourseOffering/EntryRequirementStatus';
import { StudentRequirementStatus } from 'App/CourseOffering/StudentRequirementStatus/StudentRequirementStatus';
import { StudentsExportLink } from 'App/CourseOffering/StudentsExportLink/StudentsExportLink';
import { TransferStudentToRegisteredModal } from 'App/CourseOffering/TransferStudentToRegisteredModal/TransferStudentToRegisteredModal';
import { UploadCoursePendingStudentsModal } from 'App/CourseOffering/UploadCoursePendingStudentsModal/UploadCoursePendingStudentsModal';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { ProgrammeList } from 'App/shared/ProgrammeList/ProgrammeList';
import { RejectStudentForCourseModal } from 'App/shared/RejectStudentForCourseModal/RejectStudentForCourseModal';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { Toolbar } from 'components/Toolbar/Toolbar';
import { GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getPendingPursuitsByCourseOfferingId';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetRegistrationRounds } from 'hooks/useGetRegistrationRounds';
import { STUDENTS } from 'routes';
import { PendingOrRegistered, RequirementStatus, UserAction } from 'types/__generated__';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type RowData = GetPendingPursuitsByCourseOfferingIdQuery['courseOfferingPursuits'][number];

const columnHelper = createColumnHelper<RowData>();

export function CourseOfferingPendingStudents() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-pending-students' });
  const currentLanguage = useCurrentLanguage();
  const { marblesCourseOffering } = useGetCourseOffering();
  const academicYear = marblesCourseOffering.academicYear.id;
  const registrationPeriod = marblesCourseOffering.registrationPeriod.id;
  const {
    topicSet,
    info: courseInfo,
    id: courseOfferingId,
    constrainedChoiceCluster,
    requirementNodeId,
    department,
  } = marblesCourseOffering;
  const userCanEditRegistrations = department.allowedUserActions.includes(UserAction.EditRegistrations);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [currentStudent, setCurrentStudent] = useState<
    GetPendingPursuitsByCourseOfferingIdQuery['courseOfferingPursuits'][number]['student'] | null
  >(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const queryGetRegistrationRounds = useGetRegistrationRounds(academicYear);
  const query = useQuery<GetPendingPursuitsByCourseOfferingIdQuery, GetPendingPursuitsByCourseOfferingIdQueryVariables>(
    GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID,
    { variables: { courseOfferingId, academicYear }, fetchPolicy: 'cache-and-network' }
  );

  const initialState = useMemo(() => {
    const columnVisibility: VisibilityState = {};

    if (!topicSet) {
      columnVisibility.topic = false;
    }

    if (!constrainedChoiceCluster) {
      columnVisibility['cluster-preference'] = false;
    }

    if (!requirementNodeId) {
      columnVisibility['requirement-rule'] = false;
      columnVisibility['requirement-status'] = false;
    }

    return { sorting: [{ id: 'name', desc: false }], columnVisibility };
  }, [constrainedChoiceCluster, requirementNodeId, topicSet]);

  const columns = [
    columnHelper.accessor(({ student }) => student.studentNumber, {
      id: 'student-number',
      header: t('headers.student-number'),
    }),

    columnHelper.accessor(({ student }) => student.name, {
      id: 'name',
      header: t('headers.name'),
      cell: ({ cell, row }) => (
        <Link to={generatePath(`${STUDENTS}/:studentId`, { studentId: row.original.student.id })}>
          {cell.getValue()}
        </Link>
      ),
      meta: { tableCellProps: { noWrap: true } },
    }),

    columnHelper.accessor(({ student }) => student.enrolments, {
      id: 'programme',
      header: t('headers.programme'),
      cell: ({ row }) => (
        <ProgrammeList asInlineList includeSubplans includeDates enrolments={row.original.student.enrolments} />
      ),
      filterFn: (row, _, filterValue: string) =>
        row.original.student.enrolments.some(
          ({ plan }) => plan.description.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0
        ),
    }),

    columnHelper.accessor(
      ({ requirementsCheck }) =>
        requirementsCheck?.groups.find(({ applies }) => applies)?.name || t('values.not-applicable'),
      {
        id: 'requirement-rule',
        header: t('headers.requirement-rule'),
      }
    ),

    columnHelper.accessor(
      ({ requirementsCheck }) => {
        const applicableRequirementGroup = requirementsCheck?.groups.find(({ applies }) => applies);
        const pass = applicableRequirementGroup?.rows.filter(({ status }) => status === RequirementStatus.Pass).length;
        const total = applicableRequirementGroup?.rows.length;
        const result = pass && total && pass === total ? RequirementStatus.Pass : RequirementStatus.Fail;

        return { pass, total, result };
      },
      {
        id: 'requirement-status',
        header: t('headers.requirement-status'),
        cell: ({ row, cell }) =>
          cell.getValue().pass !== undefined && cell.getValue().total !== undefined ? (
            <Button
              onClick={() => {
                setDrawerIsOpen(true);
                setCurrentStudent(row.original.student);
              }}
              variant="blank"
            >
              <EntryRequirementStatus
                status={cell.getValue().pass === cell.getValue().total ? 'completed' : 'fail'}
                title={cell.getValue().pass === cell.getValue().total ? t('values.pass') : t('values.fail')}
                label={`${cell.getValue().pass}/${cell.getValue().total}`}
              />
            </Button>
          ) : (
            t('values.not-applicable')
          ),
        enableColumnFilter: false,
        sortingFn: (rowA, rowB) => {
          const getPassAndTotal = (group: typeof applicableRequirementGroupA) => {
            if (!group) {
              // include items where group is "n/a" as lowest value of sorting logic
              return { pass: -1, total: -1 };
            }

            const pass = group?.rows.filter(({ status }) => status === RequirementStatus.Pass).length;
            const total = group?.rows.length;
            return { pass, total };
          };

          const applicableRequirementGroupA = rowA.original.requirementsCheck?.groups.find(({ applies }) => applies);
          const applicableRequirementGroupB = rowB.original.requirementsCheck?.groups.find(({ applies }) => applies);

          const { pass: passA, total: totalA } = getPassAndTotal(applicableRequirementGroupA);
          const { pass: passB, total: totalB } = getPassAndTotal(applicableRequirementGroupB);

          return passA === passB ? (totalA === totalB ? 0 : totalB - totalA) : passB - passA;
        },
      }
    ),

    columnHelper.accessor(({ topicId }) => topicSet?.topics.find(({ id }) => id === topicId)?.title[currentLanguage], {
      id: 'topic',
      header: topicSet?.title[currentLanguage],
      cell: ({ row, cell }) => {
        if (!topicSet) return null;

        const { topicId, id: coursePursuitId } = row.original;
        const { topics } = topicSet;

        return isEditMode ? (
          <SetCoursePursuitTopic topics={topics} topicId={topicId} coursePursuitId={coursePursuitId} />
        ) : (
          <>{cell.getValue()}</>
        );
      },
    }),

    columnHelper.accessor(
      ({ clusterPreference }) =>
        clusterPreference
          ? t('values.cluster-preference', {
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              index: clusterPreference.index + 1,
              pursuitCount: clusterPreference.pursuitCount,
              registrationLimit: clusterPreference.registrationLimit,
            })
          : undefined,
      {
        id: 'cluster-preference',
        header: t('headers.cluster-preference'),
      }
    ),

    columnHelper.display({
      id: 'actions',
      header: () => <span className="visually-hidden">{t('headers.actions')}</span>,
      cell: ({ row }) =>
        userCanEditRegistrations && (
          <>
            {marblesCourseOffering.studentGroups.length > 0 && (
              <TransferStudentToRegisteredModal
                student={row.original.student}
                courseInfoTitle={courseInfo?.title[currentLanguage]}
                topicId={row.original.topicId}
                isDisabled={!isEditMode}
              />
            )}
            <RejectStudentForCourseModal
              studentAndCourse={{
                coursePursuitId: row.original.id,
                student: { name: row.original.student.name, studentNumber: row.original.student.studentNumber },
              }}
              academicYear={academicYear}
              isDisabled={!isEditMode}
            />
          </>
        ),
      meta: { tableCellProps: { hasButton: true, flex: true } },
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];

  if (query.loading || queryGetRegistrationRounds.loading) return <Spinner ariaValueText={''} />;
  if (
    query.error ||
    !query.data?.courseOfferingPursuits ||
    queryGetRegistrationRounds.error ||
    queryGetRegistrationRounds.error ||
    !queryGetRegistrationRounds.data?.registrationRounds
  )
    return <>Error</>;

  const { courseOfferingPursuits } = query.data;

  const { registrationRounds } = queryGetRegistrationRounds.data;

  const applicableRegistrationRoundResultDates = registrationRounds
    .filter((registrationRound) => registrationRound.periods.some((period) => period.id === registrationPeriod))
    .map((applicableRegistrationRound) => applicableRegistrationRound.registrationResultDate);

  const lastApplicableRegistrationRoundResultDate = DateTime.max(
    ...applicableRegistrationRoundResultDates.map((dateString) => DateTime.fromISO(dateString))
  ) as DateTime;

  const isPastLastRegistrationRound = DateTime.now() > lastApplicableRegistrationRoundResultDate;

  return (
    <>
      {!isPastLastRegistrationRound && (
        <Toolbar>
          {userCanEditRegistrations && <UploadCoursePendingStudentsModal />}
          <StudentsExportLink courseOfferingId={courseOfferingId} status={PendingOrRegistered.Pending} />
          {userCanEditRegistrations && (
            <>
              <CreatePendingCoursePursuitModal />
              <ToggleSwitch
                onChange={() => setIsEditMode((previousState) => !previousState)}
                checked={isEditMode}
                label={t('edit-toggle')}
                labelPosition="before"
                extraLeftGap
              />
            </>
          )}
        </Toolbar>
      )}

      {drawerIsOpen && currentStudent && (
        <Drawer
          isOpen={drawerIsOpen}
          onClose={() => {
            setDrawerIsOpen(false);
            setCurrentStudent(null);
          }}
          title={`${t('entry-requirements')} ${currentStudent.name} (${currentStudent.studentNumber})`}
        >
          <StudentRequirementStatus student={currentStudent} />
        </Drawer>
      )}

      {courseOfferingPursuits.length ? (
        <DataTable
          columns={columns}
          data={courseOfferingPursuits}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.COURSE_OFFERING_PENDING_STUDENTS}
        />
      ) : isPastLastRegistrationRound ? (
        <InfoMessage message={t('past-last-registration-round')} hasOwnContainer={true} />
      ) : (
        <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
      )}
    </>
  );
}
