import { useLazyQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Icon,
  Label,
  FieldHint,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { ChangeEvent } from 'react';
import type {
  GetCourseOfferingsByCatalogNumberQuery,
  GetCourseOfferingsByCatalogNumberQueryVariables,
} from 'types/__generated__';

import { CourseOfferingSelector } from './CourseOfferingSelector/CourseOfferingSelector';
import { useAddCourseOfferingToConstrainedChoice } from './hooks/useAddCourseOfferingToConstrainedChoice';

import { GET_COURSE_OFFERINGS_BY_CATALOG_NUMBER } from 'graphql/queries/getCourseOfferingsByCatalogNumber';
import { MutationStatus } from 'types/__generated__';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useToast } from 'providers/ToastProvider';
import { CONSTRAINED_CHOICES_NO_PARAMS, COURSE_REGISTRATION } from 'routes';

const ID_CATALOG_NUMBER_FIELD = 'ID_CATALOG_NUMBER_FIELD';
const ID_CATALOG_NUMBER_FIELD_ERROR = 'ID_CATALOG_NUMBER_FIELD_ERROR';
const CATALOG_NUMBER_LENGTH = 10;

type SearchOffering = GetCourseOfferingsByCatalogNumberQuery['marblesCourseOfferings'][number];

interface Props extends Omit<GetCourseOfferingsByCatalogNumberQueryVariables, 'catalogNumber'> {
  clusterId: string;
  onlyAllowedRegistrationPeriod: number | undefined;
}

export function AddCourseOffering({ departmentId, academicYear, clusterId, onlyAllowedRegistrationPeriod }: Props) {
  const { t } = useTranslation('constrained-choices', { keyPrefix: 'add-course-offering' });
  const currentLanguage = useCurrentLanguage();
  const { close, isOpen, open } = useOverlayTriggerState({});
  const [getCourses, lazyQuery] = useLazyQuery<
    GetCourseOfferingsByCatalogNumberQuery,
    GetCourseOfferingsByCatalogNumberQueryVariables
  >(GET_COURSE_OFFERINGS_BY_CATALOG_NUMBER, {
    fetchPolicy: 'no-cache',
  });
  const navigate = useNavigate();

  const [mutationError, setMutationError] = useState('');
  const [currentCatalogNumberInput, setCurrentCatalogNumberInput] =
    useState<GetCourseOfferingsByCatalogNumberQueryVariables['catalogNumber']>('');
  const [offerings, setOfferings] = useState<Array<SearchOffering> | undefined>();
  const [offeringsError, setOfferingsError] = useState('');
  const [selectedOfferingId, setSelectedOfferingId] = useState('');
  const [mutate, mutation] = useAddCourseOfferingToConstrainedChoice();
  const readableMutationStatus = useGetReadableMutationStatus();
  const toastContext = useToast();

  function resetAndClose() {
    setCurrentCatalogNumberInput('');
    setOfferings(undefined);
    setSelectedOfferingId('');
    setMutationError('');
    setOfferingsError('');
    close();
  }

  function addCourse() {
    if (!selectedOfferingId) {
      setMutationError(t('modal.course-offering-not-selected'));
      return;
    }
    mutate({
      variables: { courseOfferingId: selectedOfferingId, clusterId },
      onCompleted(data) {
        const { mutationStatus } = data.setCourseOfferingConstrainedChoiceCluster;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();
          return;
        }

        if (mutationStatus === MutationStatus.ConstrainedChoiceClusterNotFound) {
          resetAndClose();
          toastContext.addToast({
            variant: 'warning',
            title: t('toast.constrained-choice-not-found'),
            content: readableMutationStatus(mutationStatus),
          });
          navigate(`${COURSE_REGISTRATION}/${CONSTRAINED_CHOICES_NO_PARAMS}/${academicYear}/${departmentId}`);
          return;
        }

        setMutationError(readableMutationStatus(mutationStatus));
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    event.target.value = event.target.value.toUpperCase();
    const catalogNumberInput = event.target.value;

    setOfferings(undefined);
    setOfferingsError('');

    if (catalogNumberInput.trim().length !== CATALOG_NUMBER_LENGTH) {
      return;
    }

    setCurrentCatalogNumberInput(catalogNumberInput);

    getCourses({
      variables: { departmentId, academicYear, catalogNumber: catalogNumberInput },
      onCompleted(data) {
        if (!data.marblesCourseOfferings.length) {
          setOfferingsError(t('modal.catalog-number-not-found', { catalogNumber: catalogNumberInput }));
          return;
        }

        setOfferings(data.marblesCourseOfferings);
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  function onSelect(event: ChangeEvent<HTMLInputElement>) {
    const offeringId = event.target.value;

    setSelectedOfferingId(offeringId);
  }

  return (
    <>
      <Button variant="primary" onClick={open}>
        <Icon name="Plus" size={20} />
        {t('trigger')}
      </Button>
      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        isDismissable={!mutation.loading}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" onClick={addCourse} disabled={mutation.loading}>
              {t('modal.buttons.add')}
            </Button>
            <Button variant="secondary" onClick={resetAndClose} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        wide
      >
        <FormField>
          <Label htmlFor={ID_CATALOG_NUMBER_FIELD}>{t('modal.catalog-number-label')}</Label>
          <InputField
            id={ID_CATALOG_NUMBER_FIELD}
            onChange={onChange}
            autoComplete="off"
            pattern="[A-Za-z0-9]{10}"
            maxLength={CATALOG_NUMBER_LENGTH}
            aria-describedby={offeringsError ? ID_CATALOG_NUMBER_FIELD_ERROR : undefined}
          />
          <FieldHint
            value={offerings && offerings.length > 0 ? `[${offerings[0].info?.title[currentLanguage]}]` : undefined}
            loading={lazyQuery.loading}
            spinnerAriaValueText={t('modal.loading-offerings')}
          />
          {offeringsError && <FeedbackBox id={ID_CATALOG_NUMBER_FIELD_ERROR} level="error" feedback={offeringsError} />}
          {offerings && offerings.length > 0 && (
            <CourseOfferingSelector
              courseOfferings={offerings}
              onOfferingSelect={onSelect}
              onlyAllowedRegistrationPeriod={onlyAllowedRegistrationPeriod}
            />
          )}
          {offerings && !offerings.length && (
            <FeedbackBox
              level="error"
              feedback={t('modal.course-offerings-not-found', {
                catalogNumber: currentCatalogNumberInput,
              })}
            />
          )}
          {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
        </FormField>
      </ModalDialog>
    </>
  );
}
