import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { GroupSwapTable } from 'App/GroupSwap/GroupSwapTable/GroupSwapTable';
import { Page } from 'components/Page/Page';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { useGetDepartmentDetails } from 'hooks/useGetDepartmentDetails';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';

export function GroupSwap() {
  const { t } = useTranslation('group-swap', { keyPrefix: 'group-swap-page' });
  const currentLanguage = useCurrentLanguage();
  const { academicYear } = useParams();
  const selectedAcademicYear = useGetAcademicYearDetails(Number(academicYear));
  const selectedDepartment = useGetDepartmentDetails();

  return (
    <Page
      title={t('title')}
      subTitle={<PageMultiSubTitle values={[selectedAcademicYear.name, selectedDepartment.name[currentLanguage]]} />}
      notification={<ArchivedYearNotification academicYear={selectedAcademicYear} />}
    >
      <section>
        <GroupSwapTable departmentId={selectedDepartment.id} academicYear={Number(academicYear)} />
      </section>
    </Page>
  );
}
