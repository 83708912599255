import { useQuery } from '@apollo/client';
import { Spinner } from '@uva-glass/component-library';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router';

import type { TabItem } from 'components/Tabs/Tabs';
import type { MarblesDepartment, GetRuleByIdQuery, GetRuleByIdQueryVariables } from 'types/__generated__';

import styles from './RuleDrawer.module.css';

import { RuleNodes } from 'App/shared/RuleNodes/RuleNodes';
import { Tabs } from 'components/Tabs/Tabs';
import { GET_RULE_BY_ID } from 'graphql/queries/getRuleById';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetDepartments } from 'hooks/useGetDepartments';
import { COURSE_REGISTRATION, ENTRY_REQUIREMENT } from 'routes';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';

interface Props {
  ruleId: string;
  hideRuleTab?: boolean;
}

export function RuleDrawer({ ruleId, hideRuleTab = false }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'rule-drawer' });
  const currentLanguage = useCurrentLanguage();
  const departments = useGetDepartments();

  const query = useQuery<GetRuleByIdQuery, GetRuleByIdQueryVariables>(GET_RULE_BY_ID, {
    variables: { ruleId },
  });

  if (query.loading) return <Spinner ariaValueText={t('loading')} />;
  if (query.error || !query.data?.rule) return <>error</>;

  const { rule } = query.data;

  const groupRequirementsByDepartmentName = (requirements: typeof rule.requirements) => {
    const groupedData: Record<string, typeof rule.requirements> = {};

    requirements.forEach((item) => {
      const departmentName = item.department.name[currentLanguage];

      if (!groupedData[departmentName]) {
        groupedData[departmentName] = [];
      }

      groupedData[departmentName].push(item);
    });

    const groupedArray = Object.entries(groupedData).map(([key, value]) => ({
      departmentName: key,
      requirements: value,
    }));

    return groupedArray;
  };

  function departmentsParentIdFilter(departments: Array<MarblesDepartment>, parentIdentifier: string | null) {
    return departments.filter(({ parentId }) => parentId === parentIdentifier);
  }

  const [orgLevel1DepartmentId] = departmentsParentIdFilter(departments, null).map(({ id }) => id);

  const orgLevel2DepartmentIds = departmentsParentIdFilter(departments, orgLevel1DepartmentId).map(({ id }) => id);

  function getPriority(item: string | null) {
    if (item === null) {
      return 0;
    }
    if (orgLevel1DepartmentId.includes(item)) {
      return 1;
    }
    if (orgLevel2DepartmentIds.includes(item)) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return 2;
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return 3;
  }

  function orgLevelSort(
    itemA: ReturnType<typeof groupRequirementsByDepartmentName>[number],
    itemB: ReturnType<typeof groupRequirementsByDepartmentName>[number]
  ) {
    const parentIdA = itemA.requirements[0].department.parentId;
    const parentIdB = itemB.requirements[0].department.parentId;

    if (parentIdA === undefined || parentIdB === undefined) {
      return 0;
    }

    const priorityA = getPriority(parentIdA);
    const priorityB = getPriority(parentIdB);

    if (priorityA < priorityB) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return -1;
    } else if (priorityA > priorityB) {
      return 1;
    } else {
      // If priorities are equal, maintain the original order
      return 0;
    }
  }

  const tabItems = [
    !hideRuleTab && {
      id: 'rule-details',
      label: t('rule-details'),
      disabled: !rule.nodes.requirements.length && !rule.nodes.groupRequirements.length,
      content: <RuleNodes rule={rule} />,
    },
    {
      id: 'entry-requirements',
      label: t('linked-to-entry-requirements', { count: rule.requirements.length }),
      disabled: !rule.requirements.length,
      content: (
        <ul className={styles['rule-drawer__requirements']}>
          {groupRequirementsByDepartmentName(rule.requirements)
            .sort(alphabeticalSortBy('departmentName'))
            .sort(orgLevelSort)
            .map((department) => (
              <li key={department.departmentName}>
                <dl className={styles['rule-drawer__requirements-department']}>
                  <dt className={styles['rule-drawer__requirements-department-name']}>{department.departmentName}</dt>
                  {department.requirements.sort(alphabeticalSortBy('name')).map((requirement) => (
                    <dd key={requirement.id}>
                      <Link
                        to={generatePath(`${COURSE_REGISTRATION}/${ENTRY_REQUIREMENT}`, {
                          id: requirement.id,
                        })}
                      >
                        {requirement.name}
                      </Link>
                    </dd>
                  ))}
                </dl>
              </li>
            ))}
        </ul>
      ),
    },
  ].filter(Boolean) as TabItem[];

  return (
    <div className={styles['rule-drawer__content']}>
      <Tabs items={tabItems} />
    </div>
  );
}
