import {
  Button,
  Icon,
  IconButton,
  Label,
  InputField,
  FeedbackBox,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import type { MutationTuple, OperationVariables } from '@apollo/client';
import type { ChangeEvent, FormEvent } from 'react';
import type { To } from 'react-router';

import styles from './EditableHeading.module.css';

import { MutationStatus } from 'types/__generated__';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';

interface Props<TData, TVariables> {
  entityId: string;
  entityName: string;
  mutationName: string;
  mutationTuple: MutationTuple<TData, TVariables>;
  onCompleted?: () => void;
  redirectToOnNotFound?: To;
  variables: TVariables;
}

const filterNonEmptyValues = <T extends Record<string, unknown>>(obj: T) =>
  Object.entries(obj)
    .filter(([, value]) => Boolean(value))
    .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {} as T);

export function EditableHeading<TData extends Record<string, unknown>, TVariables extends OperationVariables>({
  entityName,
  variables,
  mutationTuple,
  mutationName,
  onCompleted,
  redirectToOnNotFound = '..',
}: Props<TData, TVariables>) {
  const { t } = useTranslation('common', { keyPrefix: 'editable-heading' });
  const readableMutationStatus = useGetReadableMutationStatus();
  const [mutate, mutation] = mutationTuple;
  const navigate = useNavigate();

  const idInputName = useId();
  const idInputTitleNL = useId();
  const idInputTitleEN = useId();

  const inputRef = useRef<HTMLInputElement>(null);

  const [editDescription, setEditDescription] = useState(false);
  const [name, setName] = useState(variables.name);
  const [titleNL, setTitleNL] = useState(variables.titleNL ?? '');
  const [titleEN, setTitleEN] = useState(variables.titleEN ?? '');
  const [fieldNameError, setFieldNameError] = useState<string>();
  const [fieldTitleNLError, setFieldTitleNLError] = useState<string>();
  const [fieldTitleENError, setFieldTitleENError] = useState<string>();

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
  }, []);

  function resetAndClose() {
    setName(entityName);
    setTitleEN(variables.titleEN ?? '');
    setTitleNL(variables.titleNL ?? '');

    setFieldNameError(undefined);
    setFieldTitleENError(undefined);
    setFieldTitleNLError(undefined);

    setEditDescription(false);

    onCompleted && onCompleted();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const hasTitleNL = Object.hasOwn(variables, 'titleNL');
    const hasTitleEN = Object.hasOwn(variables, 'titleEN');

    if (
      !name ||
      name.trim() === '' ||
      (hasTitleNL && (!titleNL || !titleNL.trim())) ||
      (hasTitleEN && (!titleEN || !titleEN.trim()))
    ) {
      if (!name || name.trim() === '') {
        setFieldNameError(t('fill-in-a-name'));
      }

      if (hasTitleNL && (!titleNL || !titleNL.trim())) {
        setFieldTitleNLError(t('fill-in-a-title'));
      }

      if (hasTitleEN && (!titleEN || !titleEN.trim())) {
        setFieldTitleENError(t('fill-in-a-title'));
      }

      return;
    }

    const mutationVariables = filterNonEmptyValues<TVariables>({
      ...variables,
      name: name.trim(),
      titleNL: hasTitleNL && titleNL.trim(),
      titleEN: hasTitleEN && titleEN.trim(),
    });

    mutate({
      variables: mutationVariables,
      onCompleted(data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const mutationStatus = data[mutationName].mutationStatus;

        if (mutationStatus === MutationStatus.Success) {
          setEditDescription(false);

          onCompleted && onCompleted();
          return;
        }

        if (mutationStatus.endsWith('NOT_FOUND')) {
          navigate(redirectToOnNotFound);
        }

        setFieldNameError(readableMutationStatus(mutationStatus));
      },
    });
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldNameError(undefined);
    setName(event.target.value);
  }

  function onChangeTitleNL(event: ChangeEvent<HTMLInputElement>) {
    setFieldTitleNLError(undefined);
    setTitleNL(event.target.value);
  }

  function onChangeTitleEN(event: ChangeEvent<HTMLInputElement>) {
    setFieldTitleENError(undefined);
    setTitleEN(event.target.value);
  }

  return (
    <>
      <div className={styles['editable-heading']}>
        <h2 className={styles['editable-heading__title']}>{entityName}</h2>

        <IconButton aria-label={t('buttons.edit')} disabled={editDescription} onClick={() => setEditDescription(true)}>
          <Icon name="PencilSquare" />
        </IconButton>
      </div>

      {editDescription && (
        <form className={styles['editable-name-form']} onSubmit={onSubmit}>
          <FormField>
            <Label htmlFor={idInputName}>{t('name-label')}</Label>
            <InputField autoComplete="off" id={idInputName} onChange={onChange} value={name} ref={inputRef} />
            {fieldNameError && <FeedbackBox feedback={fieldNameError} level="error" />}
          </FormField>

          {Object.hasOwn(variables, 'titleNL') && (
            <FormField>
              <Label htmlFor={idInputTitleNL}>{t('title-label-nl')}</Label>
              <InputField autoComplete="off" id={idInputTitleNL} onChange={onChangeTitleNL} value={titleNL} />
              {fieldTitleNLError && <FeedbackBox feedback={fieldTitleNLError} level="error" />}
            </FormField>
          )}

          {Object.hasOwn(variables, 'titleEN') && (
            <FormField>
              <Label htmlFor={idInputTitleEN}>{t('title-label-en')}</Label>
              <InputField autoComplete="off" id={idInputTitleEN} onChange={onChangeTitleEN} value={titleEN} />
              {fieldTitleENError && <FeedbackBox feedback={fieldTitleENError} level="error" />}
            </FormField>
          )}

          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('buttons.save')}
            </Button>
            <Button variant="secondary" type="button" onClick={resetAndClose} disabled={mutation.loading}>
              {t('buttons.cancel')}
            </Button>
          </ButtonGroup>
        </form>
      )}
    </>
  );
}
