import { useQuery } from '@apollo/client';
import { FeedbackBox, Periods, Spinner } from '@uva-glass/component-library';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router';

import type { GetTopicSetByIdQuery, GetTopicSetByIdQueryVariables } from 'types/__generated__';
import type { TabItem } from 'components/Tabs/Tabs';

import styles from './TopicSetDrawer.module.css';

import { Tabs } from 'components/Tabs/Tabs';
import { GET_TOPIC_SET_BY_ID } from 'graphql/queries/getTopicSetById';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { COURSE_OFFERING, COURSE_REGISTRATION } from 'routes';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';

interface Props {
  topicSetId: string;
  hideTopicsTab?: boolean;
}

export function TopicSetDrawer({ topicSetId, hideTopicsTab = false }: Props) {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'topic-set-drawer' });
  const currentLanguage = useCurrentLanguage();

  const query = useQuery<GetTopicSetByIdQuery, GetTopicSetByIdQueryVariables>(GET_TOPIC_SET_BY_ID, {
    variables: { topicSetId },
  });

  if (query.loading) {
    return <Spinner ariaValueText={t('loading')} />;
  }

  if (query.error || !query.data?.topicSet) {
    return <FeedbackBox feedback={t('error')} level="error" />;
  }

  const { topicSet } = query.data;
  const { title, courseOfferings, topics } = topicSet;

  const tabItems = [
    !hideTopicsTab && {
      id: 'topics',
      label: t('linked-to-topics', { count: topics.length }),
      disabled: !topics.length,
      content: (
        <ul className={styles['topic-set-drawer__topics']}>
          {[...topics].sort(alphabeticalSortBy('title', currentLanguage)).map((topic) => (
            <li key={topic.id}>{topic.title[currentLanguage]}</li>
          ))}
        </ul>
      ),
    },
    {
      id: 'course-offerings',
      label: t('linked-to-courses', { count: courseOfferings.length }),
      disabled: !courseOfferings.length,
      content: (
        <ul className={styles['topic-set-drawer__courses']}>
          {[...courseOfferings]
            .sort((offering1, offering2) =>
              (offering1.info?.title[currentLanguage] ?? '').localeCompare(offering2.info?.title[currentLanguage] ?? '')
            )
            .map((courseOffering) => (
              <li key={courseOffering.id} className={styles['topic-set-drawer__courses-item']}>
                <span>{courseOffering.info?.catalogNumber}</span>
                <Link
                  to={generatePath(`${COURSE_REGISTRATION}/${COURSE_OFFERING}`, {
                    courseOfferingId: courseOffering.id,
                  })}
                >
                  {courseOffering.info?.title[currentLanguage]}
                </Link>
                <span className={styles['topic-set-drawer__courses-item-periods']}>
                  <Periods
                    periods={courseOffering.periods}
                    tooltipTranslations={{
                      periods: t('periods.periods'),
                      period: t('periods.period'),
                      and: t('periods.and'),
                    }}
                  />
                </span>
              </li>
            ))}
        </ul>
      ),
    },
  ].filter(Boolean) as TabItem[];

  return (
    <div className={styles['topic-set-drawer__content']}>
      <div className={styles['topic-set-drawer__topic-set-titles']}>
        <div>
          <span lang="nl">{title['NL']}</span> (NL)
        </div>
        <div>
          <span lang="en">{title['EN']}</span> (EN)
        </div>
      </div>

      <Tabs items={tabItems} />
    </div>
  );
}
