import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { GetPursuitsByStudentIdQuery, Student } from 'types/__generated__';

import styles from './StudentPursuits.module.css';

import { COURSE_OFFERING, COURSE_REGISTRATION } from 'routes';
import { DataTable, periodsFilterFn } from 'App/shared/DataTable/DataTable';
import { DeregisterStudentForCourseModal } from 'App/shared/DeregisterStudentForCourseModal/DeregisterStudentForCourseModal';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { RequireAuthorisation } from 'providers/AuthorisationProvider';
import { SwitchStudentGroup } from 'App/shared/SwitchStudentGroup/SwitchStudentGroup';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useFormattedDate } from 'hooks/useFormattedDate';
import { UserAction } from 'types/__generated__';

type RowData = GetPursuitsByStudentIdQuery['studentPursuits'][number];
type StudentPartial = Pick<Student, 'id' | 'studentNumber' | 'name'>;

interface Props {
  studentPursuits: GetPursuitsByStudentIdQuery['studentPursuits'];
  getGroupSwapStatus: (coursePursuitId: string) => string | undefined;
  student: StudentPartial;
  year: string;
}

const columnHelper = createColumnHelper<RowData>();

export function RegisteredStudentPursuitsTable({ studentPursuits, getGroupSwapStatus, student, year }: Props) {
  const { t } = useTranslation('student', { keyPrefix: 'student-pursuits' });
  const currentLanguage = useCurrentLanguage();
  const formattedDate = useFormattedDate();

  const [isEditMode, setIsEditMode] = useState(false);

  const initialState = {
    sorting: [{ id: `registered-${year}-period`, desc: false }],
  };

  const columns = [
    columnHelper.accessor(({ courseInfo }) => courseInfo.catalogNumber, {
      id: `registered-${year}-catalog-number`,
      header: t('headers.catalog-number'),
    }),

    columnHelper.accessor(({ courseInfo }) => courseInfo.title[currentLanguage], {
      id: `registered-${year}-title`,
      header: t('headers.title'),
      cell: ({ row, cell }) =>
        row.original.selectedOffering?.department.allowedUserActions.includes(UserAction.View) ? (
          <Link
            to={generatePath(`${COURSE_REGISTRATION}/${COURSE_OFFERING}`, {
              courseOfferingId: row.original.selectedOffering.id,
            })}
          >
            {cell.getValue()}
          </Link>
        ) : (
          cell.getValue()
        ),
    }),

    // convert accessor value to string so that it can be filtered
    columnHelper.accessor(({ courseInfo }) => courseInfo.ec.toString(), {
      id: `registered-${year}-credits`,
      header: t('headers.ec'),
    }),

    columnHelper.accessor(({ studentGroup }) => studentGroup?.name, {
      id: `registered-${year}-group`,
      header: t('headers.group'),
      cell: ({ row, cell }) => {
        const pursuitIsArchived = row.original.selectedOffering?.academicYear.isArchived;

        return (
          <>
            {row.original.studentGroup &&
            !pursuitIsArchived &&
            isEditMode &&
            row.original.selectedOffering?.department.allowedUserActions.includes(UserAction.EditRegistrations) ? (
              <SwitchStudentGroup
                coursePursuitId={row.original.id}
                currentGroup={row.original.studentGroup}
                studentGroups={row.original.selectedOffering.studentGroups}
                studentId={student.id}
              />
            ) : (
              cell.getValue()
            )}
          </>
        );
      },
    }),

    columnHelper.accessor(({ id }) => getGroupSwapStatus(id), {
      id: `registered-${year}-group-swap-request-status`,
      header: t('headers.group-swap-request-status'),
    }),

    columnHelper.accessor(
      ({ selectedOffering }) =>
        selectedOffering?.periods
          .map(({ semesterNumber, periodInSemester }) => `${semesterNumber}.${periodInSemester}`)
          .join(', '),
      {
        id: `registered-${year}-period`,
        header: t('headers.period'),
        filterFn: (row, _, filterValue) =>
          row.original.selectedOffering ? periodsFilterFn(row.original.selectedOffering.periods, filterValue) : false,
      }
    ),

    columnHelper.accessor('registrationDate', {
      id: `registered-${year}-registration-date`,
      header: t('headers.registration-date'),
      cell: ({ cell }) => (
        <>
          {cell.getValue() ? (
            <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />
          ) : (
            t('values.not-applicable')
          )}
        </>
      ),
      filterFn: (row, _, filterValue: string) =>
        row.original.registrationDate
          ? formattedDate(row.original.registrationDate, { dateStyle: 'medium', timeStyle: 'short' })
              .toLocaleLowerCase()
              .includes(filterValue.toLocaleLowerCase())
          : false,
    }),

    columnHelper.accessor('id', {
      id: `registered-${year}-action`,
      header: () => <span className="visually-hidden">{t('headers.actions')}</span>,
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row, cell }) => {
        const canEditRegistrations = row.original.selectedOffering?.department.allowedUserActions.includes(
          UserAction.EditRegistrations
        );

        if (!row.original.selectedOffering || !canEditRegistrations) return null;

        return (
          <DeregisterStudentForCourseModal
            coursePursuitId={cell.getValue()}
            student={{ name: student.name, studentNumber: student.studentNumber }}
            academicYear={row.original.selectedOffering.academicYear.id}
            isDisabled={!isEditMode}
          />
        );
      },
      meta: { tableCellProps: { hasButton: true } },
    }),
  ];

  return (
    <section className={styles['student-pursuits__table-section']}>
      <header className={styles['student-pursuits__table-header']}>
        <h2 className={styles['student-pursuits__table-heading']}>
          {t('sections.registered')} {year}
        </h2>
        <RequireAuthorisation authorisation={UserAction.EditRegistrations}>
          <ToggleSwitch
            onChange={() => setIsEditMode((previousState) => !previousState)}
            checked={isEditMode}
            label={t('toggle-editable')}
            labelPosition="before"
            extraLeftGap
          />
        </RequireAuthorisation>
      </header>
      <DataTable
        columns={columns}
        data={studentPursuits}
        initialState={initialState}
        tableId={`${TABLE_STORAGE_KEYS.REGISTERED_STUDENT_PURSUITS_TABLE}-${year}`}
      />
    </section>
  );
}
