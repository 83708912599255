import { createColumnHelper } from '@tanstack/react-table';
import { generatePath, Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import type { GetPursuitsByStudentIdQuery, Student } from 'types/__generated__';

import styles from './StudentPursuits.module.css';

import { COURSE_OFFERING, COURSE_REGISTRATION } from 'routes';
import { DataTable, periodsFilterFn } from 'App/shared/DataTable/DataTable';
import { RejectStudentForCourseModal } from 'App/shared/RejectStudentForCourseModal/RejectStudentForCourseModal';
import { RequireAuthorisation } from 'providers/AuthorisationProvider';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { UserAction } from 'types/__generated__';

type RowData = GetPursuitsByStudentIdQuery['studentPursuits'][number];
type StudentPartial = Pick<Student, 'id' | 'studentNumber' | 'name'>;

interface Props {
  studentPursuits: GetPursuitsByStudentIdQuery['studentPursuits'];
  student: StudentPartial;
  year: string;
}

const columnHelper = createColumnHelper<RowData>();

export function PendingStudentPursuitsTable({ studentPursuits, student, year }: Props) {
  const { t } = useTranslation('student', { keyPrefix: 'student-pursuits' });
  const currentLanguage = useCurrentLanguage();

  const [isEditMode, setIsEditMode] = useState(false);

  const columns = [
    columnHelper.accessor(({ courseInfo }) => courseInfo.catalogNumber, {
      id: `pending-${year}-catalog-number`,
      header: t('headers.catalog-number'),
    }),

    columnHelper.accessor(({ courseInfo }) => courseInfo.title[currentLanguage], {
      id: `pending-${year}-title`,
      header: t('headers.title'),
      cell: ({ row, cell }) =>
        row.original.selectedOffering?.department.allowedUserActions.includes(UserAction.View) ? (
          <Link
            to={generatePath(`${COURSE_REGISTRATION}/${COURSE_OFFERING}`, {
              courseOfferingId: row.original.selectedOffering.id,
            })}
          >
            {cell.getValue()}
          </Link>
        ) : (
          cell.getValue()
        ),
    }),

    // convert accessor value to string so that it can be filtered
    columnHelper.accessor(({ courseInfo }) => courseInfo.ec.toString(), {
      id: `pending-${year}-credits`,
      header: t('headers.ec'),
    }),

    columnHelper.accessor(
      ({ selectedOffering }) =>
        selectedOffering?.periods
          .map(({ semesterNumber, periodInSemester }) => `${semesterNumber}.${periodInSemester}`)
          .join(', '),
      {
        id: `pending-${year}-period`,
        header: t('headers.period'),
        filterFn: (row, _, filterValue) =>
          row.original.selectedOffering ? periodsFilterFn(row.original.selectedOffering.periods, filterValue) : false,
      }
    ),

    columnHelper.accessor(
      ({ clusterPreference }) =>
        clusterPreference
          ? t('values.cluster-preference', {
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              index: clusterPreference.index + 1,
              pursuitCount: clusterPreference.pursuitCount,
              registrationLimit: clusterPreference.registrationLimit,
            })
          : t('values.not-applicable'),
      {
        id: `pending-${year}-cluster-preference`,
        header: t('headers.cluster-preference'),
        enableSorting: false,
      }
    ),

    columnHelper.accessor('id', {
      id: `pending-${year}-action`,
      header: () => <span className="visually-hidden">{t('headers.actions')}</span>,
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row, cell }) => {
        const selectedOffering = row.original.selectedOffering;
        const canEditRegistrations = selectedOffering?.department.allowedUserActions.includes(
          UserAction.EditRegistrations
        );

        if (!canEditRegistrations || !row.original.selectedOffering) return null;

        return (
          <RejectStudentForCourseModal
            studentAndCourse={{
              coursePursuitId: cell.getValue(),
              student: { name: student.name, studentNumber: student.studentNumber },
            }}
            academicYear={row.original.selectedOffering.academicYear.id}
            isDisabled={!isEditMode}
          />
        );
      },
      meta: { tableCellProps: { hasButton: true } },
    }),
  ];

  return (
    <section className={styles['student-pursuits__table-section']}>
      <header className={styles['student-pursuits__table-header']}>
        <h2 className={styles['student-pursuits__table-heading']}>
          {t('sections.pending')} {year}
        </h2>
        <RequireAuthorisation authorisation={UserAction.EditRegistrations}>
          <ToggleSwitch
            onChange={() => setIsEditMode((previousState) => !previousState)}
            checked={isEditMode}
            label={t('toggle-editable')}
            labelPosition="before"
            extraLeftGap
          />
        </RequireAuthorisation>
      </header>
      <DataTable
        columns={columns}
        data={studentPursuits}
        tableId={`${TABLE_STORAGE_KEYS.PENDING_STUDENT_PURSUITS_TABLE}-${year}`}
      />
    </section>
  );
}
