import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Button, Drawer, EmptyStateDataDisplay, Icon, LinkButton, Spinner } from '@uva-glass/component-library';

import type {
  GetRequirementsByDepartmentIdQuery,
  GetRequirementsByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { EntryRequirementDrawer } from './EntryRequirementDrawer/EntryRequirementDrawer';
import { RemoveEntryRequirement } from './RemoveEntryRequirement';

import { CopyEntryRequirement } from 'App/EntryRequirements/EntryRequirementsTable/CopyEntryRequirement';
import { COURSE_REGISTRATION, ENTRY_REQUIREMENT } from 'routes';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { UserAction } from 'types/__generated__';

type RowData = GetRequirementsByDepartmentIdQuery['requirements'][number];

type Props = Omit<GetRequirementsByDepartmentIdQueryVariables, 'includeParentDepartments'>;

const columnHelper = createColumnHelper<RowData>();

const requirementCanBeDeleted = (requirement: RowData) =>
  requirement.courseOfferings.length === 0 && requirement.topics.length === 0;

const userCanEditRequirement = (requirement: RowData) =>
  requirement.department.allowedUserActions.includes(UserAction.EditRequirements);

const initialState = { sorting: [{ id: 'name', desc: false }] };

export function EntryRequirementsTable({ departmentId, academicYear }: Props) {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'entry-requirements' });
  const currentLanguage = useCurrentLanguage();
  const academicYearDetails = useGetAcademicYearDetails(academicYear);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [currentRequirement, setCurrentRequirement] = useState<RowData | null>(null);

  const requirementQuery = useQuery<GetRequirementsByDepartmentIdQuery, GetRequirementsByDepartmentIdQueryVariables>(
    GET_REQUIREMENTS_BY_DEPARTMENT_ID,
    { variables: { academicYear, departmentId, includeParentDepartments: true } }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('table.headers.name'),
        cell: ({ cell }) => (
          <Button
            variant="blank"
            onClick={() => {
              setDrawerIsOpen(true);
              setCurrentRequirement(cell.row.original);
            }}
          >
            {cell.getValue()}
          </Button>
        ),
        sortingFn: 'text',
      }),

      columnHelper.accessor(({ courseOfferings }) => courseOfferings.length, {
        id: 'numberOfCourseOfferings',
        header: t('table.headers.courses'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor(({ rulesInRequirement }) => rulesInRequirement.length, {
        id: 'numberOfRules',
        header: t('table.headers.rules'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor(({ topics }) => topics.length, {
        id: 'numberOfTopics',
        header: t('table.headers.topics'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor(({ department }) => department.name[currentLanguage], {
        id: 'department',
        header: t('table.headers.department'),
      }),

      columnHelper.display({
        id: 'actions',
        cell: ({ row }) =>
          userCanEditRequirement(row.original) &&
          !academicYearDetails.isArchived && (
            <RemoveEntryRequirement
              buttonIsIconOnly
              isDisabled={!requirementCanBeDeleted(row.original)}
              requirement={row.original}
            />
          ),
        meta: { tableCellProps: { hasButton: true } },
      }),
    ],
    [currentLanguage, t, academicYearDetails]
  );

  if (requirementQuery.loading) return <Spinner ariaValueText={''} />;

  if (!requirementQuery.data?.requirements) return null;

  const { requirements } = requirementQuery.data;

  return (
    <>
      {drawerIsOpen && currentRequirement && (
        <Drawer
          isOpen={drawerIsOpen}
          onClose={() => {
            setDrawerIsOpen(false);
            setCurrentRequirement(null);
          }}
          title={currentRequirement?.name}
          buttons={
            <>
              {userCanEditRequirement(currentRequirement) && !academicYearDetails.isArchived && (
                <>
                  <RemoveEntryRequirement
                    isDisabled={!requirementCanBeDeleted(currentRequirement)}
                    onCompleted={() => setDrawerIsOpen(false)}
                    requirement={currentRequirement}
                  />

                  {academicYear && <CopyEntryRequirement origin={currentRequirement} academicYear={academicYear} />}
                </>
              )}
              <LinkButton
                variant="secondary"
                to={generatePath(`${COURSE_REGISTRATION}/${ENTRY_REQUIREMENT}`, {
                  id: currentRequirement.id,
                })}
              >
                {userCanEditRequirement(currentRequirement) && !academicYearDetails.isArchived ? (
                  <>
                    <Icon name="PencilSquare" size={20} />
                    {t('buttons.edit')}
                  </>
                ) : (
                  <>
                    <Icon name="DocumentText" size={20} />
                    {t('buttons.view')}
                  </>
                )}
              </LinkButton>
            </>
          }
        >
          <EntryRequirementDrawer requirementId={currentRequirement.id} />
        </Drawer>
      )}

      {requirements.length ? (
        <DataTable
          columns={columns}
          data={requirements}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.ENTRY_REQUIREMENTS_TABLE}
        />
      ) : (
        <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
      )}
    </>
  );
}
