import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import {
  Button,
  ButtonGroup,
  Drawer,
  EmptyStateDataDisplay,
  Icon,
  LinkButton,
  Spinner,
} from '@uva-glass/component-library';

import type { GetTopicSetsByDepartmentIdQuery, GetTopicSetsByDepartmentIdQueryVariables } from 'types/__generated__';

import { TopicSetDrawer } from './TopicSetDrawer/TopicSetDrawer';

import { COURSE_REGISTRATION, TOPIC_SET } from 'routes';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { GET_TOPIC_SETS_BY_DEPARTMENT_ID } from 'graphql/queries/getTopicSetsByDepartmentId';
import { RemoveTopicSet } from 'App/TopicSets/TopicSetsTable/RemoveTopicSet';
import { RowActions } from 'App/shared/DataTable/RowActions/RowActions';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { UserAction } from 'types/__generated__';

type RowData = GetTopicSetsByDepartmentIdQuery['topicSets'][number];

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'name', desc: false }] };

export function TopicSetsTable({ departmentId, academicYear }: GetTopicSetsByDepartmentIdQueryVariables) {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'topic-sets' });
  const currentLanguage = useCurrentLanguage();
  const userCanEditTopicSet = useAuthorisation(UserAction.EditTopicSets, departmentId);
  const academicYearDetails = useGetAcademicYearDetails(academicYear);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [currentTopicSet, setCurrentTopicSet] = useState<RowData | null>(null);

  const topicSetsQuery = useQuery<GetTopicSetsByDepartmentIdQuery, GetTopicSetsByDepartmentIdQueryVariables>(
    GET_TOPIC_SETS_BY_DEPARTMENT_ID,
    { variables: { academicYear, departmentId } }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('table.headers.name'),
        cell: ({ cell }) => (
          <Button
            variant="blank"
            onClick={() => {
              setDrawerIsOpen(true);
              setCurrentTopicSet(cell.row.original);
            }}
          >
            {cell.getValue()}
          </Button>
        ),
        sortingFn: 'text',
      }),

      columnHelper.accessor(({ topics }) => topics.length, {
        id: 'numberOfTopics',
        header: t('table.headers.topics'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor(({ courseOfferings }) => courseOfferings.length, {
        id: 'numberOfCourses',
        header: t('table.headers.courses'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor(({ department }) => department.name[currentLanguage], {
        id: 'department',
        header: t('table.headers.department'),
        enableColumnFilter: true,
      }),

      columnHelper.display({
        id: 'actions',
        header: () => <span className="visually-hidden">{t('table.headers.actions')}</span>,
        cell: ({ row }) =>
          userCanEditTopicSet && (
            <RowActions>
              <RemoveTopicSet topicSet={row.original} departmentId={departmentId} academicYear={academicYear} />
            </RowActions>
          ),
        meta: { tableCellProps: { hasButton: true } },
      }),
    ],
    [academicYear, departmentId, userCanEditTopicSet, currentLanguage, t]
  );

  if (topicSetsQuery.loading) return <Spinner ariaValueText={''} />;

  if (!topicSetsQuery.data?.topicSets) return null;

  const { topicSets } = topicSetsQuery.data;

  return (
    <>
      {drawerIsOpen && currentTopicSet && (
        <Drawer
          isOpen={drawerIsOpen}
          onClose={() => {
            setDrawerIsOpen(false);
            setCurrentTopicSet(null);
          }}
          title={currentTopicSet?.name}
          buttons={
            <ButtonGroup>
              <LinkButton
                variant="secondary"
                to={generatePath(`${COURSE_REGISTRATION}/${TOPIC_SET}`, {
                  topicSetId: currentTopicSet.id,
                })}
              >
                {userCanEditTopicSet && !academicYearDetails.isArchived ? (
                  <>
                    <Icon name="PencilSquare" size={20} />
                    {t('drawer.buttons.edit')}
                  </>
                ) : (
                  <>
                    <Icon name="DocumentText" size={20} />
                    {t('drawer.buttons.view')}
                  </>
                )}
              </LinkButton>
            </ButtonGroup>
          }
        >
          <TopicSetDrawer topicSetId={currentTopicSet.id} />
        </Drawer>
      )}

      {topicSets.length ? (
        <DataTable
          columns={columns}
          data={topicSets}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.TOPIC_SETS_TABLE}
        />
      ) : (
        <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
      )}
    </>
  );
}
