import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, Drawer } from '@uva-glass/component-library';

import styles from './Rule.module.css';
import { GroupRequirements } from './GroupRequirements';
import { OptionalRequirements } from './OptionalRequirements';

import {
  UserAction,
  type GetRuleByIdQuery,
  type GetRuleByIdQueryVariables,
  type UpdateRuleNameMutation,
  type UpdateRuleNameMutationVariables,
} from 'types/__generated__';
import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { EditableHeading } from 'App/shared/EditableHeading/EditableHeading';
import { GET_RULE_BY_ID } from 'graphql/queries/getRuleById';
import { Page } from 'components/Page/Page';
import { PageSection } from 'App/shared/PageSection/PageSection';
import { RuleDrawer } from 'App/shared/RuleDrawer/RuleDrawer';
import { useUpdateRuleName } from 'App/RequirementRules/Rule/hooks/useUpdateRuleName';
import { REQUIREMENT_RULES_NO_PARAMS } from 'routes';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

export function Rule() {
  const { ruleId } = useParams<GetRuleByIdQueryVariables>();
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'rule' });
  const currentLanguage = useCurrentLanguage();
  const updateRuleName = useUpdateRuleName();
  const [rulesDrawerIsOpen, setRulesDrawerIsOpen] = useState(false);

  if (!ruleId) {
    throw new Error('No id found for entry requirement rule.');
  }

  const query = useQuery<GetRuleByIdQuery, GetRuleByIdQueryVariables>(GET_RULE_BY_ID, {
    variables: { ruleId },
  });

  if (!query.data?.rule) return null;

  const { rule } = query.data;

  const userCanEditRule = rule.department.allowedUserActions.includes(UserAction.EditRequirements);

  return (
    <Page
      title={t('name', { name: rule.name })}
      subTitle={<PageMultiSubTitle values={[rule.academicYear.name, rule.department.name[currentLanguage]]} />}
      backlink={{
        to: `../${REQUIREMENT_RULES_NO_PARAMS}/${rule.academicYear.id}/${rule.department.id}`,
        text: t('back'),
      }}
      notification={<ArchivedYearNotification academicYear={rule.academicYear} />}
    >
      <PageSection
        notification={
          <div className={styles['rule-requirement__notification']}>
            {t('used-in')}
            <Button
              variant="blank"
              onClick={() => {
                setRulesDrawerIsOpen(true);
              }}
            >
              <Trans
                t={t}
                i18nKey="notification-used"
                values={{ count: rule.requirements.length }}
                components={{ strong: <strong /> }}
              />
            </Button>
            <Trans
              t={t}
              i18nKey="notification-applied"
              values={{ count: rule.requirements.length }}
              components={{ strong: <strong /> }}
            />
          </div>
        }
      >
        {userCanEditRule && !rule.academicYear.isArchived ? (
          <EditableHeading<UpdateRuleNameMutation, UpdateRuleNameMutationVariables>
            entityId={rule.id}
            entityName={rule.name}
            mutationName="updateRuleName"
            mutationTuple={updateRuleName}
            variables={{ name: rule.name, ruleId: rule.id }}
          />
        ) : (
          <h2 className={styles['rule-heading']}>{rule.name}</h2>
        )}

        {rulesDrawerIsOpen && rule && (
          <Drawer
            isOpen={rulesDrawerIsOpen}
            onClose={() => {
              setRulesDrawerIsOpen(false);
            }}
            title={rule.name}
          >
            <RuleDrawer ruleId={rule.id} hideRuleTab={true} />
          </Drawer>
        )}
      </PageSection>

      <GroupRequirements rule={rule} />

      <OptionalRequirements rule={rule} />
    </Page>
  );
}
